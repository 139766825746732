/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Grid from './Grid';
import Skeleton from './Skeleton';

import { StyleCard } from './style';

export type CardType = 'inner';
export type CardSize = 'default' | 'small';

export interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  extra?: React.ReactNode;
  bordered?: boolean;
  headStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  loading?: boolean;
  hoverable?: boolean;
  children?: React.ReactNode;
  className?: string;
  rootClassName?: string;
  size?: CardSize;
  type?: CardType;
  cover?: React.ReactNode;
  actions?: React.ReactNode[];
  coverAlign?: 'top' | 'left' | 'bottom' | 'right';
  responsive?: boolean;
  glass?: boolean;
  imgToBackground?: boolean;
  bodyClassName?: string;
}

function getAction(actions: React.ReactNode[]): React.ReactNode[] {
  return actions.map<React.ReactNode>((action) => (
    // eslint-disable-next-line react/no-array-index-key
    <li style={{ width: `${100 / actions.length}%` }} key={uuidv4()}>
      <span>{action}</span>
    </li>
  ));
}

const Card = (props:CardProps) => {
  const {
    className,
    rootClassName,
    style,
    extra,
    headStyle = {},
    bodyStyle = {},
    title,
    loading,
    bordered = true,
    size = 'default',
    type,
    cover,
    actions,
    coverAlign = 'top',
    responsive = false,
    glass = false,
    imgToBackground = false,
    children,
    hoverable,
    bodyClassName,
    // ...others
  } = props;
  let head: React.ReactNode;
  const loadingBlock = (
    <Skeleton loading active paragraph={{ rows: 4 }} title={false}>
      {children}
    </Skeleton>
  );

  const loadingImage = (
    <Skeleton.Image active={loading} />
  );

  const isSideCard = // NOSONAR
    coverAlign === 'left' || coverAlign === 'right' ? true : false; // NOSONAR
  const isGlass = glass ? 'glass' : '';
  const isImageToBackground = imgToBackground ? 'image-full' : '';
  const mergedSize = size === 'default' ? '' : 'small';

  if (title || extra) {
    head = (
      <div className="card-head-wrapper" style={headStyle}>
        <div className="card-header">
          {title && <div className="card-title">{title}</div>}
          {extra && <div className="card-extra">{extra}</div>}
        </div>
      </div>
    );
  }

  const coverDom = cover ? <>{loading ? loadingImage : <div className="card-cover"><figure>{cover}</figure></div>}</> : null;
  const body = (
    <div className={classNames('card-body', bodyClassName)} style={bodyStyle}>
      {loading ? loadingBlock : children}
    </div>
  );

  const actionDom = actions?.length ? (
      <div className="card-footer">
        <ul className="card-actions">{getAction(actions)}</ul>
      </div>
    ) : null;

  const isContainGrid = React.useMemo<boolean>(() => {
    let containGrid = false;
    // @ts-ignore
    React.Children.forEach(children, (element: JSX.Element) => {
      if (element?.type && element.type === Grid) {
        containGrid = true;
      }
    });
    return containGrid;
  }, [children]);

  const classString = classNames(
    'card',
    {
      [`card-loading`]: loading,
      [`card-bordered`]: bordered,
      [`card-hoverable`]: hoverable,
      [`card-contain-grid`]: isContainGrid,
      [`${responsive === true ? 'lg:':''}card-side`]: isSideCard,
      [`glass`]: isGlass,
      [`image-full`]: isImageToBackground,
      [`card-${mergedSize}`]: mergedSize,
      [`card-type-${type}`]: !!type,
    },
    className,
    rootClassName,
    // hashId
  );

  const mergedStyle: React.CSSProperties = { ...style };

  return (
    <StyleCard coveralign={coverAlign} className={classString} style={mergedStyle}>
      {head}
      {coverAlign === 'top' && coverDom}
      {coverAlign === 'left' && coverDom}
      {body}
      {coverAlign !== 'bottom' && actionDom}
      {coverAlign === 'right' && coverDom}
      {coverAlign === 'bottom' && coverDom}
    </StyleCard>
  );
};

export default Card;
