import styled from 'styled-components';

export const StyleAccordion = styled.div`
  visibility: visible;
  position: relative;
  display: grid;
  overflow: hidden;
  // grid-template-rows: auto 0fr;
  // transition: grid-template-rows .2s;
  width: 100%;
  border-radius: 1rem;

  .collapse-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    padding: 1rem;
    font-weight: 600;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .collapse-content {
    visibility: visible;
    min-height: auto;
    grid-column-start: 1;
    grid-row-start: 2;
    transition: visibility .2s;
    transition: padding .2s ease-in-out,background-color .2s ease-in-out;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: unset;
    padding-bottom: 1rem;

    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .3s;
  }
`;