/* eslint-disable no-unused-vars */
import React, { useState, BaseSyntheticEvent } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import IconSvg from '../IconSvg';
import { generateMinutes } from './TimeFunctionUtils';

export type ClassNames = {
  rootContainer?: string;
  rangesContainer?: string;
  rangeButtonDefault?: string;
  rangeButtonSelected?: string;
  fromToRangeContainer?: string;
  normalCell?: string;
  normalCellHover?: string
  greyCell?: string
  invalidCell?: string
  startCell?: string
  endCell?: string
  withinRangeCell?: string
  startDot?: string;
  endDot?: string;
  footerContainer?: string;
  applyButton?: string;
  cancelButton?: string;
  timeFocus?: string;
};

export type Mode = 'start' | 'end';
export type Meridiem = 'am' | 'pm';
interface Props {
  timeChangeCallback: (newHour: number, newMinute: number) => void;
  date?: Date | null;
  twelveHoursClock?: boolean;
  timeClassNames?: ClassNames;
  selectedTime?: Date; // NOSONAR
  handleTimeChange?: (newTime: Date) => void; // NOSONAR
}

const TimeField: React.FC<Props> = ({
  timeChangeCallback,
  date,
  twelveHoursClock,
  timeClassNames,
}) => {
  const [hourFocus, setHourFocus] = useState(false);
  const [minuteFocus, setMinuteFocus] = useState(false);

  const generateHourSelectValues = () => {
    const selectValues = [];
    for (let i = twelveHoursClock ? 1 : 0; i <= (twelveHoursClock ? 12 : 23); i++) {
      selectValues.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return selectValues;
  };

  const generateMinuteSelectValues = () => {
    const minutes = generateMinutes();
    const selectValues = [];
    for (let i = 0; i < minutes.length; i++) {
      selectValues.push(
        <option key={i} value={i}>
          {minutes[i]}
        </option>
      );
    }
    return selectValues;
  };

  const generateMeridiemSelectValues = () => {
    return [
      <option key={'am'} value={'am'}>
        AM
      </option>,
      <option key={'pm'} value={'pm'}>
        PM
      </option>,
    ];
  };

  const convertHourUsingMeridiem = (hour: number, meridiem: Meridiem) => {
    if (meridiem === 'pm' && hour !== 12) {
      return hour + 12;
    } else if (meridiem === 'am' && hour === 12) return 0;
    else return hour;
  };

  const handleHourChange = (event: BaseSyntheticEvent) => {
    const newHour = twelveHoursClock
      ? convertHourUsingMeridiem(
          parseInt(event.target.value),
          dayjs(date).format('a') as Meridiem
        )
      : parseInt(event.target.value);

    const newMinute = dayjs(date).minute();
    timeChangeCallback(newHour, newMinute);

    // Update the selected date in DatePickerContext
    const newDateTime = date ? new Date(date) : new Date();

    newDateTime.setHours(newHour);
    newDateTime.setMinutes(newMinute);
  };

  const handleMinuteChange = (event: BaseSyntheticEvent) => {
    const newHour = dayjs(date).hour();
    const newMinute = parseInt(event.target.value);
    timeChangeCallback(newHour, newMinute);

    // Update the selected date in DatePickerContext
    const newDateTime = date ? new Date(date) : new Date();

    newDateTime.setMinutes(newMinute);
  };

  const handleMeridiemChange = (event: BaseSyntheticEvent) => {
    const newHour = convertHourUsingMeridiem(
      dayjs(date).hour(),
      event.target.value
    );
    const newMinute = dayjs(date).minute();
    timeChangeCallback(newHour, newMinute);

    // Update the selected date in DatePickerContext
    const newDateTime = date ? new Date(date) : new Date();

    newDateTime.setHours(newHour);
  };

  const renderSelectField = (
    valueInput: number | string,
    onChangeInput: (event: BaseSyntheticEvent) => void,
    optionsInput: JSX.Element[],
    id: string
  ) => {
    return (
      <select
        id={id + '_'}
        value={valueInput}
        onChange={onChangeInput}
        className="rounded border border-gray-200 p-1"
      >
        {optionsInput}
      </select>
    );
  };

  const hours = generateHourSelectValues();
  const minutes = generateMinuteSelectValues();
  const meridiems = generateMeridiemSelectValues();
  const hour = twelveHoursClock
    ? parseInt(dayjs(date).format('h'))
    : dayjs(date).hour();
  const minute = dayjs(date).minute();
  const meridiem = dayjs(date).format('a') as Meridiem;

  const dynamicClass = {
    [timeClassNames?.normalCell ?? '']: true,
    [timeClassNames?.startCell ?? '']: hourFocus,
    [timeClassNames?.endCell ?? '']: minuteFocus,
  };

  return (
    <div className="flex items-center justify-center gap-2 pb-2">
      <IconSvg
        title="clock"
        svgClassName="h-4 w-4 text-white dark:text-slate-500"
        path="/icons/clock.svg"
      />
      <div className="flex items-center">
        <div
          aria-hidden="true"
          className={classnames('grow', dynamicClass)}
          onFocus={() => setHourFocus(true)}
          onBlur={() => setHourFocus(false)}
        >
          {renderSelectField(hour, handleHourChange, hours, 'Hour')}
        </div>
        <div className="mx-1">:</div>
        <div
          aria-hidden="true"
          className={classnames('grow', dynamicClass)}
          onFocus={() => setMinuteFocus(true)}
          onBlur={() => setMinuteFocus(false)}
        >
          {renderSelectField(minute, handleMinuteChange, minutes, 'Minutes')}
        </div>
      </div>
      {twelveHoursClock && (
        <div className="inline-block">
          {renderSelectField(meridiem, handleMeridiemChange, meridiems, 'Meridiem')}
        </div>
      )}
    </div>
  );
};

export default TimeField;
