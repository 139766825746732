/* eslint-disable no-unused-vars */
import React from 'react';
import { StyleCheckbox } from './style';
import classNames from 'classnames';
interface Props {
  wrapperClassName?: string;
  className?: string;
  label: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hideLabel?: boolean;
  disabled?: boolean;
}

const Checkbox = ({wrapperClassName, className, label, checked, onChange, hideLabel, disabled}: Props) => {
  const nameLabel = label.toLowerCase();
  const clsWrapper = classNames({
      disabled: disabled === true,
    },
    wrapperClassName
  );

  return (
    <StyleCheckbox className={clsWrapper}>
      <input
        className={className ? `checkbox ${className}` : `checkbox`}
        type="checkbox"
        id={nameLabel}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={nameLabel} className={hideLabel === true ? 'sr-only' : `text-dark-80`}>
        {label}
      </label>
    </StyleCheckbox>
  );
};

export default Checkbox;
