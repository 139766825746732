/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  JSXElementConstructor,
} from 'react';
import classnames from 'classnames';
import type { Meta } from 'rc-field-form/lib/interface';
import defaultOptions, { IOptions } from './Options';
import DatePickerPopup from './DatePickerPopup';
import DatePickerProvider, { DatePickerContext } from './DatePickerProvider';
import { StyledDropdownContainer } from './style';

export interface IDatePickerProps {
  value?: Date;
  children?:
    | ReactElement
    | ReactElement<any, string | JSXElementConstructor<any>>;
  options?: IOptions;
  onChange?: (date: Date) => void;
  onClear?: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
  classNames?: string;
  selectedDateState?: [Date, (date: Date) => void];
  placement?:
    | 'top'
    | 'topRight'
    | 'topLeft'
    | 'bottom'
    | 'bottomRight'
    | 'bottomLeft'
    | 'left'
    | 'right';
  meta?: Meta;
  size?: 'md' | 'lg';
}

const DatePicker = ({
  value,
  children,
  options,
  onChange,
  onClear,
  classNames,
  show,
  setShow,
  selectedDateState,
  placement,
  meta,
  size,
}: IDatePickerProps) => (
  <div className={classnames('w-full', classNames)}>
    <DatePickerProvider
      options={options}
      onChange={onChange}
      onClear={onClear}
      show={show}
      setShow={setShow}
      selectedDateState={selectedDateState}
    >
      <DatePickerMain
        placement={placement}
        value={value}
        options={options}
        meta={meta}
        size={size}
      >
        {children}
      </DatePickerMain>
    </DatePickerProvider>
  </div>
);

const DatePickerMain = ({
  value,
  options: customOptions,
  children,
  placement = 'bottomRight',
  meta,
  size,
}: {
  value?: Date;
  options?: IOptions;
  children?: ReactElement;
  placement?:
    | 'top'
    | 'topRight'
    | 'topLeft'
    | 'bottom'
    | 'bottomRight'
    | 'bottomLeft'
    | 'left'
    | 'right';
  meta?: Meta;
  size?: 'md' | 'lg';
}) => {
  const options = { ...defaultOptions, ...customOptions };
  const { setShow, show } = useContext(DatePickerContext);
  const InputRef = useRef<HTMLInputElement>(null);
  const DatePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(InputRef?.current && DatePickerRef?.current)) return;
      if (
        !InputRef.current.contains(event.target as Node) &&
        !DatePickerRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', (event) =>
      handleClickOutside(event),
    );

    return () => {
      document.removeEventListener('mousedown', (event) =>
        handleClickOutside(event),
      );
    };
  }, [DatePickerRef, InputRef, setShow]);

  const getDropdownStyles = () => {
    if (!InputRef.current) return {};

    const triggerRect = InputRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let styles: React.CSSProperties = {
      display: show ? 'block' : 'none',
      minWidth: '130px',
      opacity: 1,
    };

    switch (placement) {
      case 'top':
        styles = {
          ...styles,
          top: Math.max(triggerRect.top - InputRef.current.clientHeight, 0),
          left: triggerRect.left,
        };
        break;
      case 'topRight':
        styles = {
          ...styles,
          top: Math.max(triggerRect.top - InputRef.current.clientHeight, 0),
          left: Math.min(
            triggerRect.right,
            viewportWidth - InputRef.current.clientWidth,
          ),
        };
        break;
      case 'topLeft':
        styles = {
          ...styles,
          top: Math.max(triggerRect.top - InputRef.current.clientHeight, 0),
          left: Math.max(triggerRect.left - InputRef.current.clientWidth, 0),
        };
        break;
      case 'bottom':
        styles = {
          ...styles,
          top: Math.min(triggerRect.bottom, viewportHeight),
          left: triggerRect.left,
        };
        break;
      case 'bottomRight':
        styles = {
          ...styles,
          top: Math.min(triggerRect.bottom, viewportHeight - 130),
        };
        break;
      case 'bottomLeft':
        styles = {
          ...styles,
          top: Math.min(triggerRect.bottom, viewportHeight - 130),
          left: Math.max(triggerRect.left, 0),
        };
        break;
      // Add more cases as needed
      default:
        break;
    }

    return styles;
  };

  return (
    <StyledDropdownContainer
      className={classnames('dropdown w-full', {
        [`input-error`]: meta !== undefined && meta.errors.length > 0,
      })}
    >
      {children ?? (
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <CalendarIcon />
          </div>
          <Input
            ref={InputRef}
            idProp={options?.inputIdProp}
            valueProp={value}
            nameProp={options?.inputNameProp}
            placeholderProp={options?.inputPlaceholderProp}
            dateFormat={options?.inputDateFormatProp}
            meta={meta}
            size={size}
          />
        </div>
      )}
      {show && (
        <div className="content" style={getDropdownStyles()}>
          <DatePickerPopup ref={DatePickerRef} />
        </div>
      )}
    </StyledDropdownContainer>
  );
};

const Input = forwardRef<
  HTMLInputElement,
  {
    idProp?: string;
    valueProp?: Date;
    nameProp?: string;
    placeholderProp?: string;
    dateFormat?: Intl.DateTimeFormatOptions;
    meta?: Meta;
    size?: 'md' | 'lg';
    state?: 'success' | 'warning' | 'error';
  }
>((props, ref) => {
  const { setShow, selectedDate, showSelectedDate, options, getFormattedDate } =
    useContext(DatePickerContext);

  const nameProp = props.nameProp ?? 'date';
  const idProp = props.idProp ?? nameProp;
  const placeholderProp = props.placeholderProp ?? 'Select Date';
  const valueProp = props.valueProp;
  const sizeProp = props.size ?? '';

  const format = props.dateFormat ?? null;

  const inputCls = classnames(
    'w-full pl-9',
    {
      [`input-state input-state-${props.state}`]: props.state !== undefined,
      [`input-error`]: props.meta !== undefined && props.meta.errors.length > 0,
      [`input-${props.size}`]: sizeProp === 'lg',
      [`input-default`]: sizeProp === undefined,
    },
    options?.theme?.input,
  );

  return (
    <input
      ref={ref}
      type="text"
      name={nameProp}
      id={idProp}
      className={inputCls}
      placeholder={placeholderProp}
      value={
        valueProp // NOSONAR
          ? getFormattedDate(valueProp, format) // NOSONAR
          : selectedDate && showSelectedDate // NOSONAR
            ? getFormattedDate(selectedDate, format) // NOSONAR
            : '' // NOSONAR
      } // NOSONAR
      onFocus={() => setShow(true)}
      readOnly
    />
  );
});
Input.displayName = 'Input';

const CalendarIcon = () => {
  const { options } = useContext(DatePickerContext);
  return (
    <svg
      aria-hidden="true"
      className={classnames(
        'w-5 h-5 text-gray-500 dark:text-gray-400',
        options?.theme?.inputIcon,
      )}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default DatePicker;
