import styled from 'styled-components';

export const StyleRadio = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 0.125rem;
  input {
    float: left;
    margin-left: -2rem;
    padding-left: 0;
    padding-right: 1.25rem;
    &:focus {
      outline-offset: 0px;
    }
    &.radio-secondary {
      border-color: #212121;
      &:checked, &[aria-checked="true"] {
        border-color: #212121;
        background-color:#212121;
        color: #212121;
      }
    }
  }
`;