import styled from 'styled-components';

export const StyleStatistic = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  &[class^="statistic"] [class^="statistic"],
  &[class*=" statistic"] [class^="statistic"],
  &[class^="statistic"] [class*=" statistic"],
  &[class*=" statistic"] [class*=" statistic"] {
    box-sizing: border-box;
  }
  &.bg-white {
    .statistic-title {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  &.bg-error {
    .statistic-title {
      color: rgba(255, 255, 255, 0.45);
    }
  }
  .statistic-title {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .statistic-content {
    color: rgba(0, 0, 0, 0.88);
    font-size: 24px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  }
  .statistic-content-value {
    display: inline-block;
    direction: ltr;
  }
  .statistic-content-prefix,
  .statistic-content-suffix {
    display: inline-block;
  }
  .statistic-content-prefix {
    margin-inline-end: 4px;
  }
  .skeleton {
    background-color: rgba(0, 0, 0, 0.06);
    .skeleton-content {
      .skeleton-title {
        width: 100%;
        height: 16px;
        background: rgba(0,0,0,.06);
        border-radius: 4px;
      }
    }
  }
  .skeleton {
    &.skeleton-active {
      .skeleton-image {
        aspect-ratio: 9 / 3;
      }
    }
    .skeleton-button {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 64px;
      min-width: 64px;
      height: 32px;
      line-height: 32px;
    }
    .skeleton-avatar {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      width: 32px;
      height: 32px;
      line-height: 32px;
      &.skeleton-avatar-circle {
        border-radius: 50%;
      }
    }
    .skeleton-input {
      display: inline-block;
      vertical-align: top;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 160px;
      min-width: 160px;
      height: 32px;
      line-height: 32px;
    }
    .skeleton-content {
      display: table-cell;
      width: 100%;
      vertical-align: top;
      .skeleton-paragraph {
        padding: 0;
        > li {
          width: 100%;
          height: 16px;
          list-style: none;
          background: rgba(0,0,0,.06);
          border-radius: 4px;
        }
        > li+li {
          margin-block-start: 16px;
        }
      }
    }
    &.skeleton-active {
      .skeleton-title,
      .skeleton-paragraph>li,
      .skeleton-avatar,
      .skeleton-button,
      .skeleton-input,
      .skeleton-image {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }
    }
  }
`;