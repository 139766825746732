import React from 'react';

interface LoadingPops {
  color?: string;
  loadingText?: string;
}
const Loading = ({ color = '#1990FF', loadingText }: LoadingPops) => {
  return (
    <div
      color={color}
      className="fixed z-20 bg-black bg-opacity-90 w-full min-h-screen"
    >
      <div className="absolute top-0 left-0 w-full h-screen flex justify-center items-center">
        <div className="loading-wrapper text-center">
          <div className="loading-wave">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {loadingText && (
            <span className="font-bold text-3xl text-primary-500">
              {loadingText}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loading;
