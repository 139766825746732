/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cc from './classnames';
import classNames from 'classnames';

export type colorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | undefined
  | null;

interface TagProps {
  text: string | undefined | null;
  remove: any;
  disabled?: boolean;
  className?: string;
  color?: colorType;
  bordered?: boolean;
  style?: React.CSSProperties;
  isBlock?: boolean;
}

export default function Tag(props: Readonly<TagProps>) {
  const {
    text,
    remove,
    disabled,
    className,
    color,
    bordered = true,
    style,
    isBlock,
  } = props;
  const handleOnRemove = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    remove(text);
  };

  const colorCls = classNames({
    [`badge-primary`]: color === 'primary',
    [`badge-secondary`]: color === 'secondary',
    [`badge-success`]: color === 'success',
    [`badge-error`]: color === 'error',
    [`badge-warning`]: color === 'warning',
    [`badge-info`]: color === 'info',
    [`badge-dark`]: color === 'dark',
    [`badge-default`]: color === undefined || color === null || color === 'light',
    [`badge-borderless`]: typeof color === 'string' && bordered === false,
  });

  const cls = classNames(colorCls);

  return (
    <span
      className={cc('badge', className, cls)}
      style={isBlock ? { display: 'block', paddingTop: 5 } : {}}
    >
      <span style={{ ...style }}>
        {text}
      </span>
      {!disabled && (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
          className="ml-2"
        >
          <span className="icon-close tag-close-icon">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
            </svg>
          </span>
        </button>
      )}
    </span>
  );
}
