import styled from 'styled-components';

export const StyleDivider = styled.div<{ color: string, borderWith: number }>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  border-block-start: ${props => props.borderWith ? `${props.borderWith}px` : '1px'} solid ${props => props.color ? props.color : 'rgba(5, 5, 5, 0.06)'};
  .inner-text {
    display: inline-block;
    padding: 0 1em;
  }
  &.horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 24px 0;

    &.divider-with-text {
      display: flex;
      align-items: center;
      margin: 16px 0px;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
      text-align: center;
      border-block-start: 0 ${props => props.color ? props.color : 'rgba(5, 5, 5, 0.06)'};
      position: relative;

      &:before,
      &:after {
        position: relative;
        width: 50%;
        border-block-start:  ${props => props.borderWith ? `${props.borderWith}px` : '1px'} solid transparent;
        border-block-start-color: inherit;
        border-block-end: 0;
        transform: translateY(50%);
        content: '';
      }
      &.divider-text-left {
        // text-align: left;
        &:before {
          width: 5%;
        }
        &:after {
          width: 95%;
        }
      }
      &.divider-text-right {
        // text-align: right;
        &:before {
          width: 95%;
        }
        &:after {
          width: 5%;
        }
      }
    }
    &.divider-dashed {
      border-style: dashed;
      &:before,
      &:after {
        border-style: dashed;
      }
    }
  }
  &.vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-inline-start: ${props => props.borderWith ? `${props.borderWith}px` : '1px'} solid ${props => props.color ? props.color : 'rgba(5, 5, 5, 0.06)'};
    &.divider-dashed {
      border-style: dashed;
    }
  }
  &.divider-plain {
    &.divider-with-text {
      color: rgba(0, 0, 0, 0.88);
      font-weight: normal;
      font-size: 14px;
    }
  }
`;
