import styled from 'styled-components';

export const StyleAlerts = styled.div`
  &.alert {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    align-content: flex-start;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    text-align: center;
    border-width: 1px;
    border-color: hsl(0 0% 95%);
    padding: 1rem;
    color: #1f2937;
    border-radius: 0.375rem;
    background-color: #f2f2f2;
    @media only screen and (min-width: 640px) {
      grid-auto-flow: column;
      grid-template-columns: auto minmax(auto,1fr);
      justify-items: start;
      text-align: left;
    }
    &.alert-error {
      border-color: hsl(var(--er) / 0.2);
      --tw-text-opacity: 1;
      color: hsl(var(--erc) / var(--tw-text-opacity));
      --alert-bg: hsl(var(--er));
      --alert-bg-mix: hsl(var(--b1));
      background-color: hsl(0 91% 71%);
    }
    &.alert-warning {
      border-color: hsl(var(--wa) / 0.2);
      --tw-text-opacity: 1;
      color: hsl(var(--wac) / var(--tw-text-opacity));
      --alert-bg: hsl(var(--wa));
      --alert-bg-mix: hsl(var(--b1));
      background-color: hsl(43 96% 56%);
    }
    &.alert-info {
      border-color: hsl(var(--in) / 0.2);
      --tw-text-opacity: 1;
      color: hsl(var(--inc) / var(--tw-text-opacity));
      --alert-bg: hsl(var(--in));
      --alert-bg-mix: hsl(var(--b1));
      background-color: hsl(198 93% 60%);
    }
    &.alert-success {
      border-color: hsl(var(--su)/.2);
      --tw-text-opacity: 1;
      color: hsl(var(--suc)/var(--tw-text-opacity));
      --alert-bg: hsl(var(--su));
      --alert-bg-mix: hsl(var(--b1));
      background-color: hsl(158 64% 52%);
    }
  }
`;