/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import IconWrapper from './IconWrapper';

type InnerLoadingIconProps = {
  prefixCls: string
  className?: string
  style?: React.CSSProperties
  iconClassName?: string
};

const InnerLoadingIcon = forwardRef<HTMLSpanElement, InnerLoadingIconProps>(
  ({ prefixCls, className, style, iconClassName }, ref) => {
    const mergedIconCls = classNames(`${prefixCls}-loading-icon`, className);
    const mergedIconClsName = classNames(
      `-ml-1 mr-3 h-5 w-5 animate-spin text-white`,
      iconClassName,
    );
    return (
      <IconWrapper prefixCls={prefixCls} className={mergedIconCls} style={style} ref={ref}>
        <svg
          className={mergedIconClsName}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
        >
          <circle
            className='opacity-25'
            cx={12}
            cy={12}
            r={10}
            stroke='currentColor'
            strokeWidth={4}
          />
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          />
        </svg>
      </IconWrapper>
    );
  },
);

export interface LoadingIconProps {
  prefixCls: string
  existIcon: boolean
  loading?: boolean | object
  className?: string
  style?: React.CSSProperties
}

const getCollapsedWidth = (): React.CSSProperties => ({
  width: 0,
  opacity: 0,
  transform: 'scale(0)',
});

const getRealWidth = (node: HTMLElement): React.CSSProperties => ({
  width: node.scrollWidth,
  opacity: 1,
  transform: 'scale(1)',
});

const LoadingIcon: React.FC<LoadingIconProps> = (props) => {
  const { prefixCls, loading, existIcon, className, style } = props;
  const visible = !!loading;

  if (existIcon) {
    return <InnerLoadingIcon prefixCls={prefixCls} className={className} style={style} />;
  }

  return visible ? (
    <InnerLoadingIcon prefixCls={prefixCls} className={className} style={{ ...style }} />
  ) : null;
};

export default LoadingIcon;
