import styled from 'styled-components';

export const StyleTable = styled.div`
  caption {
    display: table-caption;
    text-align: center;
  }
  .table {
    // caption-side: bottom;
    // border-collapse: collapse;
    // text-indent: initial;
    // border-spacing: 2px;
    // width: 100%;
    // margin-bottom: 1rem;
    // vertical-align: top;
    // border-color: #495057;
    width: 100%;
    text-align: start;
    border-radius: 8px 8px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    > thead {
      vertical-align: bottom;
    }
    >:not(caption)> th {
      padding: 0.5rem 0.5rem;
      color: initial;
      background-color: transparent;
      box-shadow: inset 0 0 0 9999px transparent;
    }
    &.table-striped {
      > tbody {
        > tr {
          &:nth-of-type(odd) {
            > * {
              // color: #adb5bd;
              background-color: rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
      >:not(caption) {
        >* {
          border-bottom-width: 1px;
        }
      }
    }
    &.table-bordered {
      >:not(caption) {
        >* {
          >* {
            border-width: 1px;
          }
        }
      }
    }
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    > th {
      position: relative;
      color: rgba(0,0,0,.88);
      font-weight: 600;
      text-align: start;
      background: #fafafa;
      border-bottom: 1px solid #f0f0f0;
      transition: background .2s ease;
    }
  }
`;

export const StylePagination = styled.div`
  width: 100%;
  display: flex;
  .pagination {
    margin-bottom: 0;
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    padding-left: 0;
    list-style: none;
    li {
      text-align: -webkit-match-parent;
      &.disabled {
        >.page-link {
          color: rgba(0,0,0,.25);
          cursor: not-allowed;
          &.disabled {
            color: rgba(33, 37, 41, 0.75);
            pointer-events: none;
            background-color: #e9ecef;
            border-color: #dee2e6;
          }
          pointer-events: none;
          background-color: #e9ecef;
          border-color: #dee2e6;
        }
      }
    }
  }
  .page-item {
    display: block;
    min-width: 32px;
    height: 32px;
    // margin-inline-end: 8px;
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    user-select: none;
    &:first-child {
      .page-link {
        // border-top-left-radius: 0.375rem;
        // border-bottom-left-radius: 0.375rem;
      }
    }
    &:not(:first-child) {
      .page-link {
        margin-left: calc(1px * -1);
      }
    }
    &.page-size,
    &.page-skip {
      display: inline-block;
      margin-inline-start: 16px;
      vertical-align: middle;
    }
    &.active {
      >.page-link {
        color: #1677ff;
        border-color: #1677ff;
      }
    }
    &.disabled {
      >.page-link {
        color: rgba(0,0,0,.25);
        cursor: not-allowed;
      }
    }
  }
  .page-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 6px;
    color: rgba(0, 0, 0, .88);
    transition: none;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    // color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .pagination-skip {
    white-space: nowrap;
    input {
      position: relative;
      display: inline-block;
      width: 50px;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0,0,0,.88);
      font-size: 14px;
      line-height: 1.5714285714285714;
      background-color: #fff;
      background-image: none;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 6px;
      transition: all .2s;
      height: 32px;
      box-sizing: border-box;
      margin: 0;
      margin-inline-start: 8px;
      margin-inline-end: 8px;
    }
  }
  .page-pager {
    // margin-inline-start: 8px;
  }
  .pagination-select {
    display: inline-block;
    // margin-inline-start: 16px;
    vertical-align: middle;
    select {
      width: 100%;
      height: 32px;
      padding: 4px 11px;
      position: relative;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      transition: all .2s cubic-bezier(.645,.045,.355,1);
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyleInputSearch = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
  input {
    width: 100%;
    flex-shrink: 1;
    height: 3rem;
    padding-left: 3.25rem;
    padding-right: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-width: 1px;
    // border-color: #D7D8D9;
    border-radius: 14px;
    &:focus {
      outline-offset: 0px;
    }
    &.input-lg {
      height: 3.5rem;
      min-height: 3.5rem;
    }
    &.input-warning,
    &.input-error,
    &.input-success {
      padding-right: 3.25rem;
      border-width: 2px;
      border-radius: calc(0.625rem - 2px);
    }
    &.input-warning {
      border-color: hsl(var(--wa));
    }
    &.input-error {
      border-color: #FF2D49;
    }
    &.input-success {
      border-color: hsl(var(--su));
    }
    &.input-info {
      border-color: hsl(var(--in));
    }
  }
`;