import React, { Fragment } from 'react';
import Select from './Select';
import {
  AllLeft,
  AllRight,
  Container,
  Left,
  Page,
  PageInfo,
  Right,
  StyledPage
} from './styles';

interface PProps {
  limit: number;
  offset: number;
  total?: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  showTitle?: boolean;
}

const Pagination: React.FC<PProps> = (props) => {
  const {limit, offset, total, setLimit, setOffset, showTitle = false} = props;

  if (typeof total !== 'number') {
    return <Container showtitle={`${showTitle}`}>Loading...</Container>;
  }
  const limitOptions = [10, 20, 30, 40, 50].map((value) => ({
    value,
    label: `${value}`,
    key: value
  }));
  const from = Math.min(offset + 1, total);
  const to = Math.min(offset + limit, total);
  const pageCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;
  const highestPossibleOffset = limit * (pageCount - 1);
  const pageArray = [-2, -1, 0, 1, 2]
    .map((v) => currentPage + v)
    .filter((page) => page > 0 && page <= pageCount);

  return (
    <Container showtitle={`${showTitle}`}>
      {showTitle ? (
        <PageInfo>
          Showing {from} to {to} of {total} items
          <div className="flex items-center space-x-1">
            <Select
              options={limitOptions}
              size="sm"
              className="min-w-[4rem]"
              // @ts-ignore
              value={limitOptions.find((v) => v.value === limit).value || 10}
              onChange={(v) => {
                // @ts-ignore
                setLimit(v?.value || 10);
                setOffset(0);
              }}
            />
            <span>per page</span>
          </div>
        </PageInfo>
      ):(
        ''
      )}
      {total > 0 && (
        <div className="flex items-center space-x-2">
          <StyledPage><AllLeft onClick={() => setOffset(0)} /></StyledPage>
          <StyledPage><Left onClick={() => setOffset((prev) => Math.max(prev - limit, 0))} /></StyledPage>
          {!pageArray.includes(1) && (
            <Fragment>
              <Page
                active={`${currentPage === 1}`}
                onClick={() => {
                  setOffset(0);
                }}
              >
                1
              </Page>
              <StyledPage>...</StyledPage>
            </Fragment>
          )}
          {pageArray.map((page) => {
            return (
              <Page
                key={page}
                active={`${page === currentPage}`}
                onClick={() => {
                  setOffset(limit * (page - 1));
                }}
              >
                {page}
              </Page>
            );
          })}
          {!pageArray.includes(pageCount) && (
            <Fragment>
              <StyledPage className="">...</StyledPage>
              <Page
                active={`${currentPage === pageCount}`}
                onClick={() => {
                  setOffset(highestPossibleOffset);
                }}
              >
                {pageCount}
              </Page>
            </Fragment>
          )}
          <StyledPage><Right onClick={() => setOffset((prev) => Math.min(prev + limit, highestPossibleOffset))} /></StyledPage>
          <StyledPage><AllRight onClick={() => setOffset(highestPossibleOffset)} /></StyledPage>
        </div>
      )}
    </Container>
  );
};

export default Pagination;
