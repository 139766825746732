/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import { StyleProgress } from './styles';

export type ProgressProps = {
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info'
  value?: number
  max?: number
  min?: number
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  status?: string
  className?: string
  indeterminated?: boolean
  shadow?: boolean
  striped?: boolean
  showPercent?: boolean
  showValue?: boolean
  showDescription?: boolean
  desLeft?: string | React.ReactNode;
  desRight?: string | React.ReactNode;
  placements?: 'top' | 'bottom';
};

const Progress = (props:ProgressProps) => {
  const {
    color = 'primary',
    value = 75,
    max = 100,
    min = 0,
    size = 'md',
    status,
    className = '',
    indeterminated = false,
    shadow = false,
    striped = false,
    showPercent = false,
    showValue = false,
    showDescription = false,
    desLeft,
    desRight,
    placements = 'top',
  } = props;

  const shadowCls = classNames({
    'progressbar-status-default progressbar-status-primary': color === 'primary',
    'progressbar-status-default progressbar-status-secondary': color === 'secondary',
    'progressbar-status-default progressbar-status-success': color === 'success',
    'progressbar-status-default progressbar-status-warning': color === 'warning',
    'progressbar-status-default progressbar-status-error': color === 'error',
    'progressbar-status-default progressbar-status-info': color === 'info',
  });

  const checkColor = (color: ProgressProps['color']) => {
    switch (color) {
      case 'primary':
        return `bg-primary ${shadowCls}`;
      case 'secondary':
        return `bg-secondary ${shadowCls}`;
      case 'success':
        return `bg-success ${shadowCls}`;
      case 'warning':
        return `bg-warning ${shadowCls}`;
      case 'error':
        return `bg-error ${shadowCls}`;
      case 'info':
        return `bg-info ${shadowCls}`;
      default:
        return `bg-primary ${shadowCls}`;
    }
  };

  const checkStatus = (status: ProgressProps['status']) => {
    switch (status) {
      case 'success':
        return 'success';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      case 'info':
        return 'info';
      default:
        return '';
    }
  };

  const showNumber = (value: ProgressProps['value'], max: ProgressProps['max'], min: ProgressProps['min']) => {
    if (value === undefined || max === undefined || min === undefined) {
      return '';
    } else {
      return (
        <div className="w-full flex justify-between mt-2">
          <div className="text-xs text-muted">{min}</div>
          <div className="text-xs text-muted">{max}</div>
        </div>
      );
    }
  };
  const displayDescription = () => {
    return (
      <div className="w-full flex justify-between my-2">
        <div className="text-xs text-muted">{desLeft}</div>
        <div className="text-xs text-muted">{desRight}</div>
      </div>
    );
  };

  const progressbarCls = classNames('progressbar', {
    shadowCls: shadow === true,
    'progressbar-size-xs': size === 'xs',
    'progressbar-size-sm': size === 'sm',
    'progressbar-size-md': size === 'md',
    'progressbar-size-lg': size === 'lg',
    'progressbar-size-xl': size === 'xl',
  },
  'progressbar-css');

  return (
    <StyleProgress className="progress-wrapper" value={value} {...props}>
      {showDescription && placements === 'top' ? displayDescription() : ''}
      <div // NOSONAR
        aria-label="progress" // NOSONAR
        aria-valuemax={max} // NOSONAR
        aria-valuemin={min} // NOSONAR
        aria-valuenow={value} // NOSONAR
        role="progressbar" // NOSONAR
        className={progressbarCls} // NOSONAR
      >
        <div
          className={`progress-wrapper-enter ${checkColor(color)} progress-wrapper-enter-active ${indeterminated === true ? 'indeterminated-true':''} ${className} ${striped === true ? 'striped':''} ${checkStatus(status)}`}
          color="primary">
          {showValue === true && size !== 'xs' && size !== 'sm' ? <span className="progressbar-text">{value}</span> : ''}
        </div>
      </div>
      {showPercent === true ? showNumber(value, max, min) : ''}
      {showDescription && placements === 'bottom' ? displayDescription() : ''}
    </StyleProgress>
  );
};

export default Progress;