/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";
import { FaSearch, FaInfoCircle, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { StyleInputSearch } from "./style";

interface InputSearchProps {
  className?: string;
  placeholder?: string;
  searchTerm?: string;
  setSearchTerm?: () => void;
  size?: 'lg';
  state?: 'success' | 'warning' | 'error';
  texthelp?: string;
  customIcon?: React.ReactNode;
}
const InputSearch: React.FC<InputSearchProps> = ({ className, customIcon, searchTerm, setSearchTerm, placeholder, size, state, texthelp }) => {
  const inputCls = classNames({
    [`input-${state}`]: state !== undefined,
    [`input-${size}`]: size === 'lg',
    [`input-default`]: size === undefined,
  });
  const chkState = (state: string | undefined) => {
    if (state === 'success') {
      return <FaCheckCircle className="absolute right-5 text-success" />;
    } else if (state === 'warning') {
      return <FaInfoCircle className="absolute right-5 text-warning" />;
    } else if (state === 'error') {
      return <FaTimesCircle className="absolute right-5 text-error" />;
    } else {
      return '';
    }
  };
  return (
    <StyleInputSearch className={className}>
      {customIcon}
      {!customIcon && <FaSearch className="absolute left-5 text-muted" />}
      <input
        type="text"
        className={inputCls}
        placeholder={placeholder}
        value={searchTerm}
        onChange={setSearchTerm}
      />
      {chkState(state)}
      {texthelp !== undefined ? (<div className="text-sm text-muted mt-1">{texthelp}</div>) : ('')}
    </StyleInputSearch>
  );
};

export default InputSearch;
