/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import React, { useEffect } from 'react';
import { FaAngleUp } from "react-icons/fa";
import {StyleBackTop} from './style';
import Portal from './Portal';

export interface BackTopProps {
  visibilityHeight?: number;
  duration?: number;
}

const BackTop: React.FC<BackTopProps> = (props) => {
  const {
    visibilityHeight = 400,
    duration = 450
  } = props;
  const [visible, setVisible] = React.useState<boolean>(visibilityHeight === 0);

  const ref = React.useRef<HTMLDivElement>(null);

  // changing the showTopBtn state whenever a scroll event happens
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > visibilityHeight) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  // fucntion to help scroll to top smoothly
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Portal>
      {visible && (
        <StyleBackTop ref={ref}>
          <div className="top-to-btm">
            <span className="icon-bage">
              <span className="icon-bage-content">
                <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
              </span>
            </span>
          </div>
        </StyleBackTop>
      )}
    </Portal>
  );
};

BackTop.displayName = 'BackTop';

export default BackTop;
