import { useRef, useEffect, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  children: ReactNode;
};

const Portal = ({ children }:ModalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
    return () => setMounted(false);
  }, []);
  // @ts-ignore 
  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export default Portal;

