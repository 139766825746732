/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { StyleTag } from './style';

import React, { useState } from 'react';
import { useDidUpdateEffect } from './use-did-update-effect';

import cc from './classnames';
import Tag from './Tag';
import type { colorType } from './Tag';
export interface TagsInputProps {
  name?: string;
  placeHolder?: string;
  value?: string[];
  onChange?: (tags: string[]) => void;
  onBlur?: any;
  separators?: string[];
  disableBackspaceRemove?: boolean;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
  disabled?: boolean;
  isEditOnRemove?: boolean;
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  classNames?: {
    input?: string;
    tag?: string;
  };
  color?: colorType;
  bordered?: boolean;
  style?: React.CSSProperties;
}

const defaultSeparators = ['Enter'];

const TagsInput = ({
  name,
  placeHolder,
  value,
  onChange,
  onBlur,
  separators,
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  classNames,
  color= 'primary',
  bordered,
  style,
}: TagsInputProps) => {
  const [tags, setTags] = useState<any>(value ?? []);

  useDidUpdateEffect(() => {
    onChange?.(tags);
  }, [tags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value]);

  const handleOnKeyUp = (e:React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    // @ts-ignore
    const text = e.target.value;

    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === 'Backspace'
    ) {
      // @ts-ignore
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : '';
      setTags([...tags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      if (beforeAddValidate && !beforeAddValidate(text, tags)) return;

      if (tags.includes(text)) {
        onExisting?.(text);
        return;
      }
      setTags([...tags, text]);
      // @ts-ignore
      e.target.value = '';
    }
  };

  const onTagRemove = (text: string) => {
    setTags(tags.filter((tag: any) => tag !== text));
    onRemoved?.(text);
  };

  return (
    <StyleTag aria-labelledby={name}>
      {tags.map((tag: React.Key | any) => ( //NOSONAR
        <Tag
          key={tag}
          className={classNames?.tag}
          text={tag}
          remove={onTagRemove}
          disabled={disabled}
          color={color}
          bordered={bordered}
          style={style}
        />
      ))}

      <input
        className={cc('tag--input', classNames?.input)}
        type="text"
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onBlur={onBlur}
        disabled={disabled}
        onKeyUp={onKeyUp}
      />
    </StyleTag>
  );
};

export default TagsInput;