/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useRef } from 'react';
import * as authHelper from '@utils/AuthHelpers';
import { QuillStyle } from './style';

class MyUploadAdapter {
  loader: any;
  xhr: any;
  constructor(loader: any) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      (file: any) => // NOSONAR
        new Promise(async (resolve, reject) => { // NOSONAR
          const src = URL.createObjectURL(file);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const userImage = new Image();
          userImage.src = src;
          userImage.onload = async () => {
            canvas.width = userImage.width;
            canvas.height = userImage.height;
            if (!ctx) {
              return;
            }
            ctx.drawImage(userImage, 0, 0);
            // convert canvas to webp
            const webpImage = canvas.toDataURL('image/webp', 1);
            const convertedImage = webpImage.split(',')[1];

            this._initRequest();
            this._initListeners(resolve, reject, file);
            this._sendRequest(convertedImage);
          };
        }),
    );
  }
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
  _initRequest() {
    const token = authHelper.getAuth();
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open(
      'POST',
      `${process.env.NEXT_PUBLIC_API_URL}/file/content/img/upload`,
      true,
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve: any, reject: any, file: any) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response?.error ? response.error.message : genericErrorText,
        );
      }
      resolve({
        default: response.url,
      });
    });
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt: any) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest = async (file: any) => {
    const data = JSON.stringify({
      imgFolder: 'event',
      imgData: file,
    });
    this.xhr.send(data);
  };
}

function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    const instance = new MyUploadAdapter(loader);
    return instance;
  };
}

const TextEditor = ({
  // placeholder,
  setTextEditorValue,
  textEditorValue,
}: any) => {
  const editorRef = useRef<{
    CKEditor: typeof CKEditor;
    ClassicEditor: typeof ClassicEditor;
  }>();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor }: any = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditorLoaded(true);
  }, []);

  const handleEditorChange = (event: any, editor: any) => {
    try {
      const data = editor.getData();
      if (data) {
        if (data.includes('<figure class="image">')) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, 'text/html');
          const links = doc.querySelectorAll('figure.image');
          // const links = document.querySelectorAll('a')

          links.forEach((figure) => {
            const link = document.createElement('p');

            // copy attributes

            for (const iterator of Array.from(figure?.attributes)) {
              const attr = iterator;
              link.setAttribute(attr.name, attr.value);
            }

            // copy content
            link.innerHTML = figure.innerHTML;

            // or you can use appendChild instead
            // figure.childNodes.forEach(node => link.appendChild(node))

            figure.replaceWith(link);
          });
          if (!doc.body.innerHTML.includes('<img>')) {
            setTextEditorValue(doc.body.innerHTML);
          }else{
            setTextEditorValue(data);
          }
        } else {
          setTextEditorValue(data);
        }
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <QuillStyle>
      {editorLoaded ? (
        <CKEditor
          className="mt-3 wrap-ckeditor"
          config={{
            mediaEmbed: {
              previewsInData: true,
            },
            autoParagraph: true,
            extraPlugins: [MyCustomUploadAdapterPlugin],
          }}
          editor={ClassicEditor}
          data={textEditorValue}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any, editor: any) => {
            handleEditorChange(event, editor);
          }}
        />
      ) : (
        'loading...'
      )}
    </QuillStyle>
  );
};

export default TextEditor;
