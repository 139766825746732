/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useRef, useEffect, useState, useContext, isValidElement } from 'react';
import { Badge } from '@components/elements';
import classNames from 'classnames';
import type { AvatarContextType } from './AvatarContext';
import AvatarContext from './AvatarContext';
import { StyleAvatar } from './style';

type AvatarProps = {
  src?: React.ReactNode;
  srcSet?: string;
  icon?: ReactNode;
  alt?: string;
  size?: 'ex-sm' | 'sm' | 'md' | 'lg' | 'ex-lg' | number;
  className?: string;
  shape?: 'circle' | 'square' | 'rounded';
  bordered?: boolean;
  onError?: () => boolean;
  children?: React.ReactNode;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
  style?: React.CSSProperties;
  dots?: boolean;
  stacked?: boolean;
  stackedLast?: ReactNode | string | number;
  imgClassName?: string;
};

const Avatar = (props: AvatarProps) => { // NOSONAR
  const {
    shape = 'rounded',
    src,
    srcSet,
    alt,
    size: customSize = 'default',
    className = '',
    bordered = false,
    icon,
    children,
    crossOrigin,
    style,
    dots,
    stacked,
    stackedLast,
    imgClassName,
  } = props;

  const avatarChildrenRef = useRef<HTMLSpanElement>(null);
  const [scale, setScale] = useState(1);
  const [mounted, setMounted] = useState(false);
  const [isImgExist, setIsImgExist] = useState(true);
  const avatarCtx = useContext<AvatarContextType>(AvatarContext);

  const size = customSize === 'default' ? avatarCtx?.size : customSize;
  const isNumberSize = typeof size === 'number';

  const sizing = isNumberSize
    ? { width: size, height: size, lineHeight: `${size}px` }
    : {};

  const sizeCls = classNames({
    'w-6 h-6': size === 'ex-sm',
    'w-8 h-8': size === 'sm',
    'w-10 h-10': size === 'md' || size === 'default',
    'w-20 h-20': size === 'lg',
    'w-36 h-36': size === 'ex-lg',
    [`w-[${size}px] h-[${size}px]`]: isNumberSize && size > 0,
  });

  const mergedShape = shape || avatarCtx?.shape || 'circle';

  const strCls = classNames(
    'avatar',
    sizeCls,
    {
      'p-1 border-2 border-gray-300': bordered,
      'avatar-icon': !!icon,
      [`avatar-${mergedShape} rounded-full`]: shape === 'circle',
      [`avatar-${mergedShape}`]: shape !== 'circle',
    },
    className
  );

  const childrenStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  };

  const sizeChildrenStyle: React.CSSProperties = isNumberSize
    ? { lineHeight: `${size}px` }
    : {};

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setIsImgExist(true);
    setScale(1);
  }, [src]);

  const handleImgLoadError = () => { // NOSONAR
    const { onError } = props;
    const errorFlag = onError?.();
    if (errorFlag !== false) {
      setIsImgExist(false);
    }
  };

  const hasImageElement = isValidElement(src);

  const renderDefaultAvatar = () => (
    <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
      <span className="font-medium text-gray-600">{children}</span>
    </div>
  );

  const sizeStyle: React.CSSProperties = isNumberSize
    ? {
        width: size,
        height: size,
        lineHeight: `${size}px`,
        fontSize: icon ? size / 2 : 18,
      }
    : {};

  let childrenToRender: React.ReactNode;

  if (typeof src === 'string' && isImgExist) {
    childrenToRender = (
      <img
        className={classNames({'w-full h-full object-cover': typeof size === 'number' && size > 0}, imgClassName)}
        src={src}
        srcSet={srcSet}
        alt={alt}
        crossOrigin={crossOrigin}
        style={{ ...sizing }}
      />
    );
  } else if (hasImageElement) {
    childrenToRender = src;
  } else if (icon) {
    childrenToRender = (
      <>
        {stacked !== undefined && stacked !== null && stacked === true ? children : (
          <span
            ref={avatarChildrenRef}
            style={{ ...sizeChildrenStyle, ...childrenStyle }}
          >
            {icon}
          </span>
        )}
      </>
    );
  } else if (mounted || scale !== 1) {
    childrenToRender = (
      <>
        {stacked !== undefined && stacked !== null && stacked === true ? (
          <>{children}</>
        ) : (
          <span
            ref={avatarChildrenRef}
            style={{ ...sizeChildrenStyle, ...childrenStyle }}
          >
            {children}
          </span>
        )}
      </>
    );
  } else {
    childrenToRender = (
      <>
        {stacked !== undefined && stacked !== null && stacked === true ? (
          <>{children}</>
        ) : renderDefaultAvatar()}
      </>
    );
  }

  const renderBadge = () => (
    <Badge
      wrapperClassName={classNames('relative inline-flex', {
        'rounded-full': shape === 'circle',
        'rounded': shape !== 'circle'
      })}
      placement="custom"
      placementClassName={classNames('transform -translate-y-1/2', {
        'top-2 right-2': size === 'lg',
        'top-3 right-5': size === 'ex-lg' && shape === 'circle',
        'top-2 -right-1': size === 'md' && shape === 'circle',
        'top-0 -right-1': size === 'ex-lg' && shape !== 'circle',
        'top-0 -right-1 not-circle': size !== 'ex-lg' && size !== 'lg' && shape !== 'circle',
      })}
      color="error"
    >
      <span
        className={`overflow-hidden ${strCls}`}
        style={{ ...sizeStyle, ...style }}
      >
        {childrenToRender}
      </span>
    </Badge>
  );

  const renderStackedAvatars = () => (
    <div className="flex -space-x-4">
      {childrenToRender}
      {typeof stackedLast === 'string' || typeof stackedLast === 'number' ? (
        <StyleAvatar>
          <button
            className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600"
            onClick={() => {/* Handle button click here */}}
          >
            {stackedLast}
          </button>
        </StyleAvatar>
      ) : (
        stackedLast
      )}
    </div>
  );

  let result;
  if (dots) {
    result = renderBadge();
  } else if (stacked) {
    result = renderStackedAvatars();
  } else {
    result = (
      <StyleAvatar className={strCls} style={{ ...sizeStyle, ...style }}>
        {childrenToRender}
      </StyleAvatar>
    );
  }
  return result;
};

export default Avatar;
