import styled from 'styled-components';

export const StyleInputSearch = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
  input {
    width: 100%;
    flex-shrink: 1;
    height: 3rem;
    padding-left: 3.25rem;
    padding-right: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-width: 1px;
    // border-color: #D7D8D9;
    border-radius: 14px;
    &:focus {
      outline-offset: 0px;
    }
    &.input-lg {
      height: 3.5rem;
      min-height: 3.5rem;
    }
    &.input-warning,
    &.input-error,
    &.input-success {
      padding-right: 3.25rem;
      border-width: 2px;
      border-radius: calc(0.625rem - 2px);
    }
    &.input-warning {
      border-color: hsl(var(--wa));
    }
    &.input-error {
      border-color: #FF2D49;
    }
    &.input-success {
      border-color: hsl(var(--su));
    }
    &.input-info {
      border-color: hsl(var(--in));
    }
  }
`;