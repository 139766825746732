/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ReactDataTable from 'react-data-table-component';
import Search from './Search';
import FullPageLoader from './FullPageLoader';
import useFullPageLoader from './useFullPageLoader';

type PaginationOptionComp = {
  rowsPerPageText: string;
  rangeSeparatorText: string;
  noRowsPerPage: boolean;
  selectAllRowsItem: boolean;
  selectAllRowsItemText: string;
};

type TableProps = {
  caption?: string;
  columns: any;
  dataSource?: any;
  pagination?: any;
  titleLeft?: React.ReactNode;
  titleRight?: React.ReactNode;
  showSearch?: boolean;
  striped?: boolean;
  responsive?: boolean;
  total?: number; // NOSONAR
  pageSize?: number;
  pageSizeOptions?: number[];
  onSearch?: (value: any) => void;
  customTable?: any;
  placeholder?: string;
  selectableRows?: boolean;
  onSelectedRowsChange?: (value: any) => void;
  expandableRows?: boolean;
  paginationComponentOptions?: PaginationOptionComp;
  loading?: boolean;
};

const headers = [
  { key: 'id', title: 'No#', dataIndex: 'id', sortable: false },
  { key: 'name', title: 'Name', dataIndex: 'name', sortable: true },
  { key: 'email', title: 'Email', dataIndex: 'email', sortable: true },
  { key: 'body', title: 'Comment', dataIndex: 'body', sortable: false },
];

const paginationOptions = {
  rowsPerPageText: 'Rows per page:',
  rangeSeparatorText: 'of',
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: 'All'
};

const DataTable = (props: TableProps) => {
  const {
    caption,
    columns = headers,
    pagination = true,
    titleLeft,
    titleRight,
    showSearch = true,
    responsive = true,
    onSearch,
    pageSize = 20,
    pageSizeOptions = [10, 20, 30, 40, 50],
    dataSource,
    customTable,
    placeholder,
    selectableRows = false,
    onSelectedRowsChange,
    expandableRows = false,
    striped = false,
    paginationComponentOptions = paginationOptions,
    loading,
  } = props;
  const [comments, setComments] = useState([]);
  const [loader] = useFullPageLoader();

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setComments(dataSource);
    }
  }, [dataSource]);

  return (
    <div className="relative space-y-4">
      {titleLeft || titleRight ? (
        <div className="flex items-center justify-between">
          <div></div>
          {titleLeft}
          {titleRight}
        </div>
      ) : null}
      {showSearch ? (
        <Search
          placeholder={placeholder}
          onSearch={(value) => {
            if (onSearch) {
              onSearch(value);
            }
          }}
        />
      ) : null}
      {customTable ?? (
        <div className="relative">
          {caption && <p className="text-sm text-gray-500">{caption}</p>}
          <ReactDataTable
            striped={striped}
            columns={columns}
            data={comments}
            pagination={pagination}
            responsive={responsive}
            progressPending={loading}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={pageSizeOptions}
            selectableRows={selectableRows}
            onSelectedRowsChange={onSelectedRowsChange}
            expandableRows={expandableRows}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      )}
      {loader ? <FullPageLoader /> : null}
    </div>
  );
};

export default DataTable;
