import styled from 'styled-components';

export const StyleTooltip = styled.span`
  &.tooltip-wrapper {
    display: inline-block;
    position: relative;
  }

  /* Absolute positioning */
  .tooltip-tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: #ffffff;
    background: #000000;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    &:before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
    }
    &.top {
      top: calc(30px * -1);
      &:before {
        top: 100%;
        border-top-color: #000000;
      }
    }
    &.right {
      left: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &:before {
        left: calc(6px * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: #000000;
      }
    }
    &.bottom {
      bottom: calc(30px * -1);
      &:before {
        bottom: 100%;
        border-bottom-color: #000000;
      }
    }
    &.left {
      left: auto;
      right: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &:before {
        left: auto;
        right: calc(6px * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: #000000;
      }
    }
  }
`;

export const StylePopup = styled.span`
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: #040005;
  padding: 1px;
  opacity: .9;
  &::before {
    content: "";
    border: solid transparent;
    border-color: #040005;
    border-width: 5px;
    height: 0;
    position: absolute;
    pointer-events: none;
    transform: rotate(45deg);
    width: 0;
    z-index: -1;
    top: -3px;
    left: 3px;
  }
  &.tooltip-content {
    color: white;
    font-size: 16px;
  }
  .tooltip-inner {
    padding: 8px 10px;
    color: #333;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #b1b1b1;
  }
`;