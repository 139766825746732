/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";
import defaultOptions, { IOptions } from "./Options";
import { getFormattedDate as formatDate } from './date';

interface IDatePickerContext {
	options: IOptions
	view: Views
	setView: Dispatch<SetStateAction<Views>>
	show: boolean
	setShow: (show: boolean) => void
	selectedDate: Date
  changeSelectedDate: (
    action: 'prev' | 'next' | 'date' | 'today',
    date: Date
  ) => void;
	showSelectedDate: boolean
	setShowSelectedDate: Dispatch<SetStateAction<boolean>>
	setSelectedTime: Dispatch<SetStateAction<Date | null>>
	selectedMonth: number
	selectedYear: number
	onClear: () => void
	getFormattedDate: (date: Date | number, formatOptions?: Intl.DateTimeFormatOptions | null | undefined) => string
}

export type Views = "days" | "months" | "years" | "decades";

export const DatePickerContext = createContext<IDatePickerContext>({
	options: defaultOptions,
	view: "days",
	setView: () => {},
	show: false,
	setShow: () => {},
	selectedDate: new Date(),
	changeSelectedDate: () => {},
	showSelectedDate: true,
	setShowSelectedDate: () => {},
	selectedMonth: 0,
	selectedYear: 0,
	setSelectedTime: () => {},
	onClear: () => {},
	getFormattedDate: () => "",
});

interface IDatePickerProviderProps {
	children: ReactElement
	options?: IOptions
	onChange?: (date: Date, dateString?: string) => void
	onClear?: () => void
	show: boolean
	setShow: (show: boolean) => void
	selectedDateState?: [Date, (date: Date) => void]
}

const DatePickerProvider = ({ children, options: customOptions, onChange, onClear, show, setShow, selectedDateState }: IDatePickerProviderProps) => {
	const options = { ...defaultOptions, ...customOptions };
	const [view, setView] = useState<Views>("days");
	// Use conditional logic to set the initial state for selectedDate
	const initialSelectedDate = selectedDateState
		? selectedDateState[0]
		: options?.defaultDate ?? new Date();
	const [selectedDate, setSelectedDate] = useState<Date>(initialSelectedDate);
	const [selectedTime, setSelectedTime] = useState<Date | null>(null);

	const [showSelectedDate, setShowSelectedDate] = useState<boolean>(options?.defaultDate !== null);
	const selectedMonth = selectedDate.getMonth();
	const selectedYear = selectedDate.getFullYear();

	const changeSelectedDate = (action: "prev" | "next" | "date" | "today" | "time", date: Date) => {
		if (options?.maxDate && date > options.maxDate) return;
		if (options?.minDate && date < options.minDate) return;
		if (options?.disabledDates && options.disabledDates.indexOf(date) >= 0) return;
		setSelectedDate(date);
		setShowSelectedDate(true);
		if (!options?.showTime && options?.autoHide && view === "days" && action === "date") setShow(false);
		if (options?.showTime && options?.autoHide && view === "days" && action === "time") setShow(false);
		if (onChange) onChange(date);
	};

	const getFormattedDate = (
		date: Date | number,
		formatOptions?: Intl.DateTimeFormatOptions | undefined | null
	) =>
		formatDate(options?.language ? options?.language : 'en', date, formatOptions);

	// Memoize the context value object
	const contextValue = useMemo(
		() => ({
			options,
			view,
			setView,
			show,
			setShow,
			selectedDate,
			changeSelectedDate,
			showSelectedDate,
			setShowSelectedDate,
			selectedMonth,
			selectedYear,
			selectedTime,
			setSelectedTime,
			onClear: onClear ?? (() => {}),
			getFormattedDate
		}),
		[
			options,
			view,
			show,
			selectedDate,
			showSelectedDate,
			selectedMonth,
			selectedYear,
			selectedTime,
			onClear,
			getFormattedDate,
			changeSelectedDate,
			setShow
		]
	);

	return (
		<DatePickerContext.Provider value={contextValue}>
			{children}
		</DatePickerContext.Provider>
	);
};

export default DatePickerProvider;
