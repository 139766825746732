/* eslint-disable no-unused-vars */
import React from 'react';
import { FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { StyleInput } from './style';

const Input: React.FC<{
  size?: 'lg';
  className?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  state?: 'success' | 'warning' | 'error';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  texthelp?: string;
}> = ({
  size = '',
  className,
  placeholder,
  value,
  disabled,
  onChange,
  autoComplete,
  state,
  texthelp,
}) => {
  return (
    <StyleInput
      className={`${className}${disabled === true ? ` disabled` : ''}`} //NOSONAR
    >
      <input
        disabled={disabled}
        value={value}
        // defaultValue={value}
        onChange={onChange}
        type="time"
        placeholder={placeholder}
        className={`${size === 'lg' ? `input-lg ` : ''}${ //NOSONAR
          state ? ` input-${state}` : ''
        }`}
        autoComplete={autoComplete}
      />
      {state === 'success' ? (
        <FaCheckCircle className="absolute right-5 text-success" /> //NOSONAR
      ) : state === 'warning' ? ( //NOSONAR
        <FaInfoCircle className="absolute right-5 text-warning" /> //NOSONAR
      ) : state === 'error' ? ( //NOSONAR
        <FaTimesCircle className="absolute right-5 text-error" /> //NOSONAR
      ) : (
        ''
      )}
      {texthelp !== undefined ? (
        <div className="text-sm text-muted mt-1">{texthelp}</div>
      ) : (
        ''
      )}
    </StyleInput>
  );
};

export default Input;
