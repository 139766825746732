import React, { useState } from "react";
import FullPageLoader from "./FullPageLoader";

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false);
    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);
    return [
        loading === true ? <FullPageLoader /> : '',
        () => showLoader(), //Show loader
        () => hideLoader() //Hide Loader
    ];
};

export default useFullPageLoader;