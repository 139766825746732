/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';

const isValidNumber = (value: number) =>
  typeof value === 'number' && isFinite(value);

interface MapViewProps {
  isView?: boolean;
  // eslint-disable-line no-unused-vars
  callbackLatLng?: (initialLat: number, initialLng: number) => void;
  initialLat?: number;
  initialLng?: number;
  draggable?: boolean;
  search?: boolean;
}

const MapView: React.FC<MapViewProps> = (props) => {
  const {
    isView = true,
    callbackLatLng,
    initialLat = 13.756331,
    initialLng = 100.501762,
    draggable = true,
    search = true,
  } = props;
  const [selectedPlace, setSelectedPlace] = useState<any | null>(null); // NOSONAR
  const [searchLngLat, setSearchLngLat] = useState<any | null>(null); // NOSONAR
  const [currentLocation, setCurrentLocation] = useState<any | null>(null); // NOSONAR
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [inputLat, setInputLat] = useState<number>(initialLat); // NOSONAR
  const [inputLng, setInputLng] = useState<number>(initialLng); // NOSONAR
  const [markerDraggable, setMarkerDraggable] = useState<boolean>(draggable);
  const [searchResult, setSearchResult] = useState<any | null>(null); // NOSONAR

  // Load script for Google Map
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? '',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>Loading....</div>;

  const center = {
    lat: isValidNumber(initialLat) ? initialLat : 13.756331,
    lng: isValidNumber(initialLng) ? initialLng : 100.501762,
  };

  // Handle place change on search
  const handlePlaceChanged = () => {
    if (searchResult !== null) {
      const place = searchResult.getPlace(); // NOSONAR
      setSelectedPlace(place);
      const newLat = place?.geometry?.location?.lat();
      const newLng = place?.geometry?.location?.lng();
      setSearchLngLat({ lat: newLat, lng: newLng });
      setCurrentLocation(null);
      setInputLat(newLat ?? 0);
      setInputLng(newLng ?? 0);
      setMarkerDraggable(true);
      if (newLat && newLng) {
        callbackLatLng?.(newLat, newLng);
      }
    }
  };

  // Get current location
  const handleGetLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( // NOSONAR
        (position) => {
          const { latitude, longitude } = position.coords;
          setSelectedPlace(null);
          setSearchLngLat(null);
          setCurrentLocation({ lat: latitude, lng: longitude });
          setInputLat(latitude);
          setInputLng(longitude);
        },
        (error) => {
          console.error('Error getting location:', error.message);

          // You can present a user-friendly message here
          alert(
            'Unable to retrieve your location. Please check your browser settings.',
          );
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      // You can present a user-friendly message here
      alert('Geolocation is not supported by your browser.');
    }
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    const newLat = e.latLng?.lat() ?? 0;
    const newLng = e.latLng?.lng() ?? 0;
    setSearchLngLat({ lat: newLat, lng: newLng });
    setSelectedPlace(null);
    setCurrentLocation(null);
    setInputLat(newLat);
    setInputLng(newLng);
  };

  // On map load
  // @ts-ignore
  const onMapLoad = (map: google.maps.Map<Element>) => {
    const controlDiv = document.createElement('button');
    const controlUI = document.createElement('div');
    controlUI.innerHTML = 'Get Location';
    controlUI.addEventListener('click', handleGetLocationClick);
    controlDiv.style.background = 'none padding-box rgb(255, 255, 255)';
    controlDiv.style.border = '0px';
    controlDiv.style.display = 'table-cell';
    controlDiv.style.margin = '10px';
    controlDiv.style.padding = '0px 17px';
    controlDiv.style.textTransform = 'none';
    controlDiv.style.appearance = 'none';
    controlDiv.style.cursor = 'pointer';
    controlDiv.style.userSelect = 'none';
    controlDiv.style.overflow = 'hidden';
    controlDiv.style.verticalAlign = 'middle';
    controlDiv.style.textAlign = 'center';
    controlDiv.style.height = '40px';
    controlDiv.style.minWidth = '66px';
    controlDiv.style.fontSize = '18px';
    controlDiv.style.color = 'rgb(86, 86, 86)';
    controlDiv.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    controlDiv.appendChild(controlUI);

    if (!isView) {
      map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        controlDiv,
      );
    }

    // Add a draggable marker
    const marker = new google.maps.Marker({
      position: center,
      map,
      title: 'Drag me!',
      draggable: markerDraggable,
    });

    // Event listener for drag end
    window.google.maps.event.addListener(
      marker,
      'dragend',
      (event: google.maps.MapMouseEvent) => {
        handleMarkerDragEnd(event);
        const lat = event.latLng?.lat();
        const lng = event.latLng?.lng();

        if (lat !== undefined && lng !== undefined) {
          callbackLatLng?.(lat, lng);
        }
      },
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {/* Search component  */}
      {search && (
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocompleteRef.current ?? autocomplete;
            setSearchResult(autocomplete);
          }}
          options={{ fields: ['address_components', 'geometry', 'name'] }}
          onPlaceChanged={handlePlaceChanged}
          // onPlaceChanged={() => {
          //   handlePlaceChanged();
          //   // Call the callback with the new latLng values from the search
          //   callbackLatLng(
          //     searchLngLat.lat,
          //     searchLngLat.lng
          //   );
          // }}
        >
          <input
            className="input input-bordered w-full "
            type="text"
            placeholder="ระบุสถานที่"
          />
        </Autocomplete>
      )}

      {/* Map component  */}
      <GoogleMap
        zoom={currentLocation || selectedPlace ? 18 : 17}
        center={currentLocation || searchLngLat || center}
        mapContainerClassName="map"
        mapContainerStyle={{ width: '100%', height: '400px', margin: 'auto' }}
        onLoad={onMapLoad}
      >
        {selectedPlace && <Marker position={searchLngLat} />}
        {!isView && currentLocation && <Marker position={currentLocation} />}
      </GoogleMap>
    </div>
  );
};

MapView.propTypes = {
  isView: PropTypes.bool,
  callbackLatLng: PropTypes.func,
  initialLat: PropTypes.number,
  initialLng: PropTypes.number,
  draggable: PropTypes.bool,
  search: PropTypes.bool,
};

export default MapView;
