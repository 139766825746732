/* eslint-disable no-unused-vars */
import React from 'react';
import Slider from 'rc-slider';
import classNames from 'classnames';

export interface SliderProps<ValueType = number | number[]> {
  className?: string;
  onChange: (value: ValueType) => void;
  value?: ValueType;
  min?: number;
  max?: number;
  step?: number;
  range?: boolean;
  defaultValue?: ValueType;
}

const SliderComponent = (props: SliderProps) => {
  const { className, onChange, min = 1, max = 3, step = 0.1, value, range, defaultValue } = props;
  return (
    <Slider
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      step={step}
      min={min}
      max={max}
      range={range}
      className={classNames("text-2xl", className)}
      marks={{
        1: "1",
        3: "3",
      }}
      // tipFormatter={(value: number) => value}
    />
  );
};

export default SliderComponent;
