import Image from 'next/image';
import classNames from 'classnames';

interface Props {
  src: string;
  alt: string;
  imgWidth: string;
  priority?: boolean;
  imgClassName?: string;
}

const Image83 = ({ src, alt, priority = false, imgClassName, imgWidth }: Props) => {

  return (
    <figure className={classNames('relative aspect-8/3', imgClassName)} style={{width: imgWidth}}>
      <Image
        src={src}
        alt={alt}
        priority={priority}
        fill
        style={{
          objectFit: 'cover',
        }}
        quality={80}
        className='rounded-lg'
        placeholder="blur"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkKLxTDwADlAHO08KrNQAAAABJRU5ErkJggg=="
        // Gen TRANSPARENT BASE64 PNG https://png-pixel.com/
      />
    </figure>
  );
};

export default Image83;
