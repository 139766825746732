/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const QuillStyle = styled.div`
  .ck-editor__editable_inline{
    min-height: 500px;
  }
  .ck-source-editing-area {
    text-align: left;
  }
  .ck.ck-editor__editable_inline{
    p,h2,h3,h4,ul,ol{
      margin-bottom: 15px;
    }
    a{
      color: #0071DC;
      text-decoration: underline;
    }
    ul,ol{
      padding-left: 25px;
    }
  }
`;
