/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from './context';
import BreadcrumbItem, { InternalBreadcrumbItem } from './BreadcrumbItem';
import BreadcrumbSeparator from './BreadcrumbSeparator';

import { StyleBreadcrumb } from './style';
import useItemRender from './useItemRender';
import useItems from './useItems';
import type { AnyObject } from './context';

export interface BreadcrumbItemType {
  key?: React.Key;
  /**
   * Different with `path`. Directly set the link of this item.
   */
  href?: string;
  /**
   * Different with `href`. It will concat all prev `path` to the current one.
   */
  path?: string;
  title?: React.ReactNode;
  /* @deprecated Please use `title` instead */
  breadcrumbName?: string;
  // menu?: BreadcrumbItemProps['menu'];
  /** @deprecated Please use `menu` instead */
  overlay?: React.ReactNode;
  className?: string;
  // dropdownProps?: DropdownProps;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>;

  /** @deprecated Please use `menu` instead */
  children?: Omit<BreadcrumbItemType, 'children'>[];
}
export interface BreadcrumbSeparatorType {
  type: 'separator';
  separator?: React.ReactNode;
}

export type ItemType = Partial<BreadcrumbItemType & BreadcrumbSeparatorType>;

export type InternalRouteType = Partial<
  BreadcrumbItemType & BreadcrumbSeparatorType
>;

export interface BreadcrumbProps<T extends AnyObject = AnyObject> {
  prefixCls?: string;
  params?: T;
  separator?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  rootClassName?: string;
  children?: React.ReactNode;

  /** @deprecated Please use `items` instead */
  routes?: ItemType[];

  items?: ItemType[];

  itemRender?: (
    route: ItemType,
    params: T,
    routes: ItemType[],
    paths: string[],
  ) => React.ReactNode;
}

const getPath = <T extends AnyObject = AnyObject>(params: T, path?: string) => {
  if (path === undefined) {
    return path;
  }
  let mergedPath = (path || '').replace(/^\//, '');
  Object.keys(params).forEach((key) => {
    mergedPath = mergedPath.replace(`:${key}`, params[key]!); // NOSONAR
  });
  return mergedPath;
};

const Breadcrumb = <T extends AnyObject = AnyObject>(
  props: BreadcrumbProps<T>,
) => {
  const {
    separator = '/',
    style,
    className,
    rootClassName,
    items,
    // children,
    itemRender,
    params = {},
    ...restProps
  } = props;
  const { direction, breadcrumb } = React.useContext(ConfigContext);

  let crumbs: React.ReactNode;
  const prefixCls = 'breadcrumb';

  const mergedItems = useItems(items);

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const mergedItemRender = useItemRender(prefixCls, itemRender);

  if (mergedItems && mergedItems.length > 0) {
    // generated by route
    const paths: string[] = [];

    const itemRenderRoutes = items;

    crumbs = mergedItems.map((item, index) => {
      const {
        path,
        key,
        type,
        // menu,
        // overlay,
        className: itemClassName,
        separator: itemSeparator,
        // dropdownProps
      } = item;
      const mergedPath = getPath(params, path);

      if (mergedPath !== undefined) {
        paths.push(mergedPath);
      }

      const mergedKey = key ?? index;
      const isLastItem = index === mergedItems.length - 1;

      let { href } = item;
      if (paths.length && mergedPath !== undefined) {
        href = `#/${paths.join('/')}`;
      }

      return (
        <InternalBreadcrumbItem
          key={mergedKey}
          className={itemClassName}
          href={href}
          separator={isLastItem ? '' : separator}
        >
          {mergedItemRender(item, params, itemRenderRoutes!, paths, href)}
          {type === 'separator' ? (
            <BreadcrumbSeparator key={mergedKey}>
              {itemSeparator}
            </BreadcrumbSeparator>
          ) : null}
        </InternalBreadcrumbItem>
      );
    });
  }

  const breadcrumbClassName = classNames(
    'text-sm breadcrumb',
    breadcrumb?.className,
    {
      [`breadcrumb-rtl`]: direction === 'rtl',
    },
    className,
    rootClassName,
  );

  const mergedStyle: React.CSSProperties = { ...breadcrumb?.style, ...style };

  return (
    <StyleBreadcrumb>
      <nav className={breadcrumbClassName} style={mergedStyle} {...restProps}>
        <ul>{crumbs}</ul>
      </nav>
    </StyleBreadcrumb>
  );
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
