/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { FaInfoCircle, FaCheckCircle, FaTimesCircle, FaEyeSlash, FaEye } from 'react-icons/fa';
import { StyleInput } from './style';
import classNames from 'classnames';
import type { Meta } from 'rc-field-form/lib/interface';

const Input: React.FC<{
  refs?: React.RefObject<HTMLInputElement>
  id?: string
  name?: string
  type?: 'text' | 'email' | 'password' | 'search'
  size?: 'md' | 'lg'
  rootClassName?: string
  className?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  autoComplete?: string
  state?: 'success' | 'warning' | 'error'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  texthelp?: string,
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  visibilityToggle?: {
    visible: boolean
    onVisibleChange: (visible: boolean) => void
  }
  readOnly?: boolean
  meta?: Meta
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}> = (props) => {
  const {
    refs,
    id,
    size = '',
    type = 'text',
    rootClassName,
    className,
    placeholder,
    value,
    disabled,
    onChange,
    autoComplete,
    state,
    texthelp,
    name,
    prefix,
    suffix,
    visibilityToggle,
    meta,
    readOnly,
    onFocus,
  } = props;
  const [showPasswordState, setShowPasswordState] = React.useState(visibilityToggle?.visible);
  let updatedType = type;
  const refsInput = useRef<HTMLInputElement | null>(refs ? refs.current : null);

  if (visibilityToggle) {
    updatedType = showPasswordState === false ? 'password' : 'text';
  }

  const rootCls = classNames(
    {
      disabled: disabled === true,
    },
    rootClassName,
  );
  const inputCls = classNames({
    [`input-state input-state-${state}`]: state !== undefined,
    [`input-error`]: meta !== undefined && meta.errors.length > 0,
    [`input-${size}`]: size === 'lg',
    [`input-default`]: size === undefined,
    [`input-has-suffix`]: suffix !== undefined || visibilityToggle !== undefined,
    [`input-has-prefix`]: prefix !== undefined,
  }, className);

  const chkState = (state: string | undefined) => {
    if (state === 'success') {
      return <FaCheckCircle className='absolute right-5 text-success' />;
    } else if (state === 'warning') {
      return <FaInfoCircle className='absolute right-5 text-warning' />;
    } else if (state === 'error') {
      return <FaTimesCircle className='absolute right-5 text-error' />;
    } else {
      return '';
    }
  };

  return (
    <StyleInput className={rootCls}>
      {prefix && (
        <span className='input-prefix'>
          <span className='input-prefix-icon'>
            {prefix}
          </span>
        </span>
      )}
      <input
        ref={refsInput}
        disabled={disabled}
        value={value}
        id={id ?? name}
        // defaultValue={value}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        type={updatedType}
        placeholder={placeholder}
        className={inputCls}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {visibilityToggle && (
        <span className='input-suffix input-password'>
          {showPasswordState === true ? (
            <span aria-hidden="true" className='input-suffix-password' onClick={() => setShowPasswordState(!showPasswordState)}>
              <FaEye
                className='cursor-pointer text-muted'
              />
            </span>
          ) : (
            <span aria-hidden="true" className='input-suffix-password' onClick={() => setShowPasswordState(!showPasswordState)}>
              <FaEyeSlash
                className='cursor-pointer text-muted'
              />
            </span>
          )}
        </span>
      )}
      {suffix && (
        <span className='input-suffix'>
          <span className='input-suffix-icon'>
            {suffix}
          </span>
        </span>
      )}
      {state && (
        <span className='input-state input-suffix'>
          <span className='input-suffix-icon'>
            {chkState(state)}
          </span>
        </span>
      )}
      {texthelp !== undefined ? <div className='text-muted mt-1 text-sm'>{texthelp}</div> : ''}
    </StyleInput>
  );
};

export default Input;
