/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react';
import { AiFillDownCircle, AiFillUpCircle } from 'react-icons/ai';
import { StyleAccordion } from './style';
import classNames from 'classnames';

type AccordionProps = {
  open?: boolean;
  children: ReactNode | ReactNode[] | undefined | null;
  expandIconOpen?: React.ReactNode;
  expandIconClose?: React.ReactNode;
  iconPostion?: 'left' | 'right';
  collapseHeaderColor?: string;
};

type AccordionItemProps = {
  key: string | number;
  children?: React.ReactNode;
  collapseKey: string | number;
  label: string;
};

const Accordion = (props: AccordionProps) => {
  const {
    open,
    children,
    iconPostion,
    expandIconOpen,
    expandIconClose,
    collapseHeaderColor,
  } = props;

  const [isOpen, setIsOpen] = useState(
    open ?? (React.Children.toArray(children) as React.ReactElement<AccordionItemProps>[])[0].props.collapseKey ? true : false // NOSONAR
  );
  const [activeCollapse, setActiveCollapse] = useState(
    (React.Children.toArray(children) as React.ReactElement<AccordionItemProps>[])[0].props.collapseKey
  );

  const onToggle = (key: string | number) => {
    if (key === activeCollapse) {
      setActiveCollapse(key);
      setIsOpen(!isOpen);
    } else {
      setActiveCollapse(key);
      setIsOpen(true);
    }
  };

  const renderIcon = (collapseState: string | number, active: string | number) => {
    if (collapseState === active && isOpen === true) {
      return expandIconOpen ?? <AiFillUpCircle className="text-primary" />;
    } else {
      return expandIconClose ?? <AiFillDownCircle className="text-primary" />;
    }
  };

  const collapseHeadCls = classNames(
    'collapse-header',
    collapseHeaderColor,
    'space-x-2 text-xl font-medium',
    {
      'justify-between': iconPostion === 'right',
    }
  );

  return (
    <StyleAccordion>
      {React.Children.map(children, (child, index) => {
        const { collapseKey, label, key } = (child as React.ReactElement<AccordionItemProps>).props;
        return (
          <div
            tabIndex={key as any}
            className={classNames('collapse divide-y overflow-hidden', {
              'border rounded-t-2xl': index === 0,
              'border-l border-r': index !== 0 && index !== React.Children.count(children) - 1,
              'border rounded-b-2xl': index === React.Children.count(children) - 1,
            })}
            key={key as any}
          >
            <button
              className={collapseHeadCls}
              type="button"
              data-toggle="collapse"
              data-target={`#${collapseKey}`}
              aria-expanded="true"
              onClick={() => onToggle(collapseKey)}
            >
              {iconPostion === 'left' ? renderIcon(collapseKey, activeCollapse) : ''}
              {label}
              {iconPostion === 'right' ? renderIcon(collapseKey, activeCollapse) : ''}
            </button>
            <div
              className={`collapse-content rounded-none ${collapseKey === activeCollapse && isOpen === true ? 'block' : 'hidden'}`}
              style={{
                maskImage: 'linear-gradient(to bottom, white, white, transparent)',
              }}
            >
              {React.Children.map(children, (child: any) => {
                if (child.props.collapseKey !== activeCollapse) return undefined;
                return child.props.children;
              })}
            </div>
          </div>
        );
      })}
    </StyleAccordion>
  );
};

export default Accordion;

// @ts-ignore
export const Panel = function ({ children, ...props }) {
  return <section {...props}>{children}</section>;
};
