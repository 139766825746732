/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import { StyleSwitch } from './style';
import React from 'react';

interface SwitchProps {
  wrapperClassName?: string;
  className?: string;
  label: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  hideLabel?: boolean;
  id?: string;
  size?: 'sm' | 'lg';
}

const Switch = (props: SwitchProps) => {
  const { wrapperClassName, className, label, id, checked, size, onChange, hideLabel } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onChange?.(e.target.checked);
  };

  const nameLabel =  id ?? label.toString().toLowerCase().replace(' ', '-');
  const cls = classNames('switch', {
    'switch-sm': size === 'sm',
    'switch-lg': size === 'lg'
  }, wrapperClassName);

  const toggleCls = classNames('toggle', {
    'toggle-sm': size === 'sm',
    'toggle-lg': size === 'lg'
  }, className);

  const descriptionId = `description-${nameLabel}`;
  const labelBy = nameLabel + ' ' + descriptionId;

  return (
    <StyleSwitch className={cls}>
      <input
        role="switch"
        className={toggleCls}
        type="checkbox"
        id={nameLabel}
        checked={checked}
        onChange={handleOnChange}
        aria-checked={checked}
        aria-labelledby={labelBy}
      />
      <label htmlFor={nameLabel} className={hideLabel === true ? 'sr-only' : `text-dark-80`}>
        {label}
      </label>
    </StyleSwitch>
  );
};

export default Switch;
