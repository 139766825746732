import styled from 'styled-components';

export const StyleEmpty = styled.div`
  position: relative;
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
  .empty-image {
    height: 40px;
    margin-bottom: 8px;
    svg {
      height: 100%;
      margin: auto;
    }
  }
`;