import * as React from 'react';

export type AvatarSize = 'ex-sm' | 'sm' | 'md' | 'lg' | 'ex-lg' | 'default' | number;

export interface AvatarContextType {
  size?: AvatarSize;
  shape?: 'circle' | 'square';
}

const AvatarContext = React.createContext<AvatarContextType>({
  size: 'default',
  shape: undefined
});

export default AvatarContext;
