import { NumericFormat } from 'react-number-format';

interface NumberFormatPops {
  amount: number;
}
const NumberFormat = ({ amount }: NumberFormatPops) => {
  return (
    <NumericFormat value={amount} displayType="text" thousandSeparator="," />
  );
};

export default NumberFormat;