import React from "react";
import { FaSpinner } from "react-icons/fa";

const FullPageLoader = () => {
    return (
        <div className="fp-container">
            <FaSpinner className="fp-loader" />
        </div>
    );
};

export default FullPageLoader;