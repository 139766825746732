import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import type { SkeletonElementProps } from './Element';
import Element from './Element';
import { StyleskeletonClsLoading } from './style';

export interface AvatarProps extends Omit<SkeletonElementProps, 'shape'> {
  shape?: 'circle' | 'square';
}

const SkeletonAvatar: React.FC<AvatarProps> = (props) => {
  const {
    className,
    rootClassName,
    active,
    shape = 'circle',
    size = 'default'
  } = props;

  const otherProps = omit(props, ['prefixCls', 'className']);
  const cls = classNames(
    'skeleton',
    `skeleton-element`,
    {
      [`skeleton-active`]: active
    },
    className,
    rootClassName,
  );

  return (
    <StyleskeletonClsLoading className={cls}>
      <Element
        prefixCls="skeleton-avatar"
        shape={shape}
        size={size}
        {...otherProps}
      />
    </StyleskeletonClsLoading>
  );
};

export default SkeletonAvatar;
