import styled from 'styled-components';

export const StyleNavs = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: stretch;
  padding-top: 0!important;
  padding-bottom: 0!important;
  border-bottom-width: 2px;
  border-color: transparent!important;
  &.nav-horizontal {
    > * + * {
      margin-left: 1rem;
    }
  }
  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0!important;
    padding-bottom: 0!important;
    margin-bottom: -2px;
    .nav-link {
      color: #99A1B7;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: color .2s ease;
      padding: 0.5rem 0;
      &.active {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid #3E97FF;
        transition: color .2s ease;
      }
    }
  }
`;