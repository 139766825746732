import styled from 'styled-components';

export const StyleAvatar = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  > span {
    display: inline-block;
    &.rounded-full {
      overflow: hidden;
      img {
        border-radius: 9999px;
      }
    }
    &.avatar-rounded {
      overflow: hidden;
      border-radius: 14px;
      img {
        border-radius: 14px;
      }
    }
    span {
      background-color: #cccccc;
    }
  }
  &.avatar {
    &.avatar-rounded {
      border-radius: 14px;
      img {
        border-radius: 14px;
      }
    }
  }
`;