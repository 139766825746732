import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import type { SkeletonElementProps } from './Element';
import Element from './Element';
import { StyleskeletonClsLoading } from './style';

export interface SkeletonInputProps
  extends Omit<SkeletonElementProps, 'size' | 'shape'> {
  size?: 'large' | 'small' | 'default';
  block?: boolean;
}

const SkeletonInput: React.FC<SkeletonInputProps> = (props) => {
  const {
    className,
    rootClassName,
    active,
    block,
    size = 'default'
  } = props;
  const prefixCls = 'skeleton';

  const otherProps = omit(props, ['prefixCls']);
  const cls = classNames(
    prefixCls,
    `${prefixCls}-element`,
    {
      [`${prefixCls}-active`]: active,
      [`${prefixCls}-block`]: block
    },
    className,
    rootClassName,
  );

  return (
    <StyleskeletonClsLoading className={cls}>
      <Element prefixCls={`${prefixCls}-input`} size={size} {...otherProps} />
    </StyleskeletonClsLoading>
  );
};

export default SkeletonInput;
