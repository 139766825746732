import styled from 'styled-components';

export const StyleBadge = styled.span`
  font-size: 11px;
  line-height: 17px;
  padding: 0 8px;
  &.badge-primary {
    background-color: #DFEFFF;
    border-color: #DFEFFF;
    color: #1990FF;
  }
  &.badge-secondary {
    background-color: #ECEBF6;
    border-color: #ECEBF6;
    color: #7C7A8A;
  }
  &.badge-error {
    background-color: #FFEAED;
    border-color: #FFEAED;
    color: #FF2D49;
  }
  &.badge-warning {
    background-color: #FFEFD4;
    border-color: #FFEFD4;
    color: #DC8800;
  }
  &.badge-success {
    background-color: #D9F5DF;
    border-color: #D9F5DF;
    color: #11C739;
  }
  &.badge-info {
    background-color: #7FC1FF;
    border-color: #7FC1FF;
    color: #ffffff;
  }
  &.badge-light {
    background-color: #ffffff;
    border-color: #7C7A8A;
    color: #7C7A8A;
  }
  &.badge-dark {
    background-color: #282732;
    border-color: #282732;
    color: #ffffff;
  }
  &.chip {
    &.chip-primary {
      background-color: #DFEFFF;
      color: #1990FF;
    }
    &.chip-secondary {
      background-color: #ECEBF6;
      color: #7C7A8A;
    }
    &.chip-error {
      background-color: #FFEAED;
      color: #FF2D49;
    }
    &.chip-warning {
      background-color: #FFEFD4;
      color: #DC8800;
    }
    &.chip-success {
      background-color: #E9FFEE;
      color: #11C739;
    }
    &.chip-info {
      background-color: #DFEFFF;
      color: #019EF7;
    }
    &.chip-light {
      background-color: #ECEBF6;
      color: #7C7A8A;
    }
    &.chip-dark {
      background-color: #282732;
      color: #FFFFFF;
    }
  }
  // #DFDEEA
`;