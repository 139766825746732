import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { StyledDropdownContainer } from './style';

export interface DropdownProps {
  trigger?: ReactNode;
  content: ReactNode;
  placement?: 'top' | 'topRight' | 'topLeft' | 'bottom' | 'bottomRight' | 'bottomLeft' | 'left' | 'right';
  animation?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ trigger, content, placement = 'bottom', animation = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getDropdownStyles = () => {
    if (!dropdownRef.current) return {};
    if (isOpen === false) return { display: 'none'};

    const triggerRect = dropdownRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let styles: React.CSSProperties = {
      minWidth: '130px',
    };
    switch (placement) {
      case 'top':
        styles = { ...styles, top: Math.max(triggerRect.top - dropdownRef.current.clientHeight, 0), left: triggerRect.left };
        break;
      case 'topRight':
        styles = { ...styles, top: Math.max(triggerRect.top - dropdownRef.current.clientHeight, 0), left: Math.min(triggerRect.right, viewportWidth - dropdownRef.current.clientWidth) };
        break;
      case 'topLeft':
        styles = { ...styles, top: Math.max(triggerRect.top - dropdownRef.current.clientHeight, 0), left: Math.max(triggerRect.left - dropdownRef.current.clientWidth, 0) };
        break;
      case 'bottom':
        styles = { ...styles, top: Math.min(triggerRect.bottom, viewportHeight), left: triggerRect.left };
        break;
      case 'bottomRight':
        styles = { ...styles, top: Math.min(triggerRect.bottom, viewportHeight), left:  Math.max(triggerRect.left - (dropdownRef.current.clientWidth + Math.min(triggerRect.bottom, viewportHeight)), 0) };
        break;
      case 'bottomLeft':
        styles = { ...styles, top: Math.min(triggerRect.bottom, viewportHeight), left: Math.max(triggerRect.left - dropdownRef.current.clientWidth, 0) };
        break;
      // Add more cases as needed
      default:
        break;
    }

    return styles;
  };

  return (
    <StyledDropdownContainer className="dropdown" ref={dropdownRef}>
      <div aria-hidden="true" className="trigger" onClick={toggleDropdown}>
        {trigger}
      </div>
      {animation && isOpen ? (
        <div className={`content mt-2 ${isOpen ? 'open' : 'closed'}`} style={getDropdownStyles()}>
          {content}
        </div>
      ) : (
        isOpen && (
          <div ref={contentRef} className="content" style={getDropdownStyles()}>
            {content}
          </div>
        )
      )}
    </StyledDropdownContainer>
  );
};

export default Dropdown;
