export const phoneRegexThai = /^(0[689]+\d{8})+$/i;
export const phoneRegexNine = /^0\d{8}$/;
export const phoneRegexSixSix = /^(\+66|0)\d\d{7,8}$/i;
export const regexThaiAccountName = /^[ก-๙a-zA-Z0-9\s]+$/;
//NOSONAR /^[ก-๙a-zA-Z\d\s.-_]+$/
export const regexThaiAccountNameWithSpecial = /^[ก-๙a-zA-Z\d\s.\-_]+$/;
export const regexThaiName = /^[ก-๙a-zA-Z\s]+$/;
export const regexNameWithSpecial = /^[A-Za-zก-ฮะ-์ ,.'-]+$/;
export const checkRealNumber = /^\d*\.\?\d*$/;
export const checkNumber = /^\d*$/;
