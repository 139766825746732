/* eslint-disable no-unused-vars */
import React, {
  SetStateAction,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import _ from 'lodash';
import { StyleSelect } from './style';
import classNames from 'classnames';

export type TypeValueAll = string | number | boolean;
export type TypeValueTwo = string | number;

export type SelectedOptionType = {
  key?: TypeValueTwo;
  value: TypeValueAll;
  label: TypeValueTwo;
};

type IconProps = {
  className?: string;
};

const Icon: React.FC<IconProps> = ({ className }): JSX.Element => {
  return (
    <svg className={className} height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon: React.FC<IconProps> = ({ className }): JSX.Element => {
  return (
    <svg className={className} height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

type SelectedOptionProps = {
  placeHolder?: string;
  options: SelectedOptionType[];
  isMulti?: boolean;
  isSearchable?: boolean;
  className?: string;
  value?: TypeValueAll;
  onChange: (
    value:
      | TypeValueAll
      | SelectedOptionType
      | SelectedOptionType[]
      | SetStateAction<string>
      | undefined
  ) => void | ((prevState: string) => string) | undefined;
  disabled?: boolean;
  size?: 'lg' | 'sm';
  texthelp?: string;
  state?: 'success' | 'warning' | 'error' | 'info';
  onSelected?: (value: TypeValueAll) => void;
};

const Select = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  className = '',
  disabled = false,
  value,
  size,
  texthelp,
  state,
  onSelected,
}: SelectedOptionProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const valueSelected = _.find(options, function (o) {
    return o.value === value;
  });
  const [selectedValue, setSelectedValue] = useState<
    SelectedOptionType | SelectedOptionType[] | null | undefined
  >(isMulti && Array.isArray(value) ? value : valueSelected);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current?.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (e: { target: any }) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });
  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (
      !selectedValue ||
      (Array.isArray(selectedValue) && selectedValue.length === 0)
    ) {
      return <div className="select-selected-placeholder">{placeHolder}</div>;
    }

    if (isMulti && Array.isArray(selectedValue)) {
      return (
        <div className="select-tags">
          {selectedValue.map((option: SelectedOptionType) => (
            <div key={option.key ?? option.label} className="select-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onTagRemove(e, option);
                  }
                }}
                aria-hidden="true"
                className="select-tag-close"
              >
                <CloseIcon className={state ? ` fill-${state}` : ''} />
              </span>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="select-selected-value">
        {!Array.isArray(selectedValue) && selectedValue?.label}
      </div>
    );
  };

  const removeOption = (option: SelectedOptionType) => {
    if (Array.isArray(selectedValue)) {
      return selectedValue.filter((o) => o.value !== option.value);
    }
  };

  const onTagRemove = (
    e:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | KeyboardEvent<HTMLSpanElement>,
    option: SelectedOptionType
  ) => {
    e.stopPropagation();

    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: SelectedOptionType) => {
    let newValue: SelectedOptionType | SelectedOptionType[] | undefined;

    if (isMulti && Array.isArray(selectedValue)) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option: SelectedOptionType) => {
    if (isMulti && Array.isArray(selectedValue)) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }
    return (
      !Array.isArray(selectedValue) && selectedValue.value === option.value
    );
  };

  const onSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) =>
      typeof option.label !== 'number'
        ? option.label?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        : option.label.toString().indexOf(searchValue.toString())
    );
  };

  const clsSelect = classNames(
    'select-container',
    {
      disabled: disabled,
      [`select-${state}`]: state,
      'select-lg': size === 'lg',
      'select-sm': size === 'sm',
    },
    className
  );

  return (
    <StyleSelect className={clsSelect}>
      <div
        ref={inputRef}
        aria-hidden="true"
        onClick={handleInputClick}
        className="select-input"
      >
        {getDisplay()}
        <div className="select-tools">
          <div className="select-tool">
            <Icon className={state ? ` fill-${state}` : ''} />
          </div>
        </div>
      </div>
      {showMenu && (
        <div className='select-menu'>
          {isSearchable && (
            <div className='search-box'>
              <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
          )}
          {getOptions().map((option) => (
            <div
              onClick={() => {
                onItemClick(option);
                onSelected?.(option.value);
              }}
              key={option.key}
              aria-hidden='true'
              className={`select-item ${isSelected(option) && 'selected'}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {texthelp !== undefined && <div className='text-muted mt-1 text-sm'>{texthelp}</div>}
    </StyleSelect>
  );
};

export default Select;
