import styled from 'styled-components';

export const StyledDropdownContainer = styled.div`
  .dropdown {
    position: relative;
  }

  .trigger {
    cursor: pointer;
  }

  .content {
    position: fixed;
    background-color: #fff;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 10;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 14px;
    box-shadow: 0px 4px 7px 0px #00000021;
    &.open {
      opacity: 1;
    }
    > * + * {
      padding: 0.25rem 0.5rem;
    }
  }
  .dropdown-item {
    cursor: pointer;
    color: #504E66;
    font-size: 0.875rem;
    line-height: 2.55rem;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 14px;
    &:hover {
      background-color: #DFEFFF;
    }
  }
  &.input-error
    input {
      border-width: 1px;
      border-color: #FF2D49;
      &:focus-visible,
      &:focus {
        outline-offset: 0px;
        border-width: 1px;
        // outline-width: 0px;
      }
    }
  }
  input {
    width: 100%;
    flex-shrink: 1;
    height: 3rem;
    padding-left: 2.25rem;
    padding-right: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-width: 1px;
    // border-color: #D7D8D9;
    border-radius: 14px;
    &:focus-visible,
    &:focus {
      outline-offset: 0px;
      border-width: 1px;
      // outline-width: 0px;
    }
    &.input-lg {
      height: 3.5rem;
      min-height: 3.5rem;
    }
    &.input-warning,
    &.input-error,
    &.input-success {
      padding-right: 3.25rem;
      border-width: 1px;
      border-radius: calc(0.625rem - 1px);
    }
    &.input-warning {
      border-color: hsl(var(--wa));
    }
    &.input-error {
      border-color: #FF2D49;
      &:focus {
        outline-offset: 0px;
        border-width: 1px;
        border-color: #FF2D49;
      }
    }
    &.input-success {
      border-color: hsl(var(--su));
    }
    &.input-info {
      border-color: hsl(var(--in));
    }
    &.input-has-suffix {
      padding-right: 3.25rem;
    }
    &.form-control-plaintext {
      display: block;
      width: 100%;
      padding: 0.375rem 0;
      margin-bottom: 0;
      line-height: 1.5;
      background-color: transparent;
      border: solid transparent;
      border-width: 1px;
      &:focus {
        outline: 0;
      }
    }
  }
`;
