import styled from 'styled-components';

export const StyleSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 0.65rem;
  min-height: 1.5rem;
  padding-left: 3.5rem;
  margin-bottom: 0.125rem;
  &.switch-lg {
    padding-left: 6.5rem;
    input {
      margin-left: -6.5rem;
    }
  }
  &.switch-sm {
    padding-left: 2.5rem;
    input {
      margin-left: -2.5rem;
    }
  }
  input {
    float: left;
    margin-left: -3.5rem;
    &:focus {
      outline-offset: 0px;
    }
    &.toggle-lg {
      height: 3.5rem;
      min-height: 3.5rem;
      width: 6rem;
    }
    &.toggle-sm {
      height: 1.25rem;
      width: 2rem;
    }
  }
`;