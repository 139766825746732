import * as React from 'react';
import type { FormatConfig, valueType } from './utils';

interface NumberProps extends FormatConfig {
  value: valueType;
}

const StatisticNumber: React.FC<NumberProps> = (props) => { // NOSONAR
  const {
    value,
    formatter,
    precision,
    decimalSeparator,
    groupSeparator = '',
  } = props;

  let valueNode: React.ReactNode;

  if (typeof formatter === 'function') {
    // Customize formatter
    valueNode = formatter(value);
  } else {
    // Internal formatter
    const val = String(value);
    const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/); // NOSONAR

    // Process if illegal number
    if (!cells || val === '-') {
      valueNode = val;
    } else {
      const negative = cells[1];
      let int = cells[2] || '0';
      let decimal = cells[4] || '';
      int = int.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator); // NOSONAR

      if (typeof precision === 'number') {
        decimal = decimal
          .padEnd(precision, '0')
          .slice(0, precision > 0 ? precision : 0);
      }

      if (decimal) {
        decimal = `${decimalSeparator}${decimal}`;
      }

      valueNode = [
        <span key="int" className={`statistic-content-value-int`}>
          {negative}
          {int}
        </span>,
        decimal && (
          <span key="decimal" className={`statistic-content-value-decimal`}>
            {decimal}
          </span>
        )
      ];
    }
  }

  return <span className={`statistic-content-value`}>{valueNode}</span>;
};

export default StatisticNumber;
