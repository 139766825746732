import styled from 'styled-components';

export const StyleTag = styled.div`
  --tag-bg: #fff;
  --tag-border: #ccc;
  --tag-main: #3182ce;
  --tag-radius: 0.375rem;
  --tag-s: 0.5rem;
  --tag-tag: #edf2f7;
  --tag-remove: #e53e3e;
  --tag-padding: 0.15rem 0.25rem;

  /* Container Styles */
  align-items: center;
  background: var(--tag-bg);
  border: 1px solid var(--tag-border);
  border-radius: var(--tag-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--tag-s);
  line-height: 1.4;
  padding: var(--tag-s);
  * {
    box-sizing: border-box;
    transition: all 0.2s ease;
  }
  &:focus-within {
    border-color: var(--tag-main);
    box-shadow: var(--tag-main) 0px 0px 0px 1px;
  }
  .tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    display: inline-flex;
    height: auto;
    margin-inline-end: 8px;
    padding-inline: 7px;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.02);
    border-width: 1px;
    border-style: solid;border-radius: 4px;
    opacity: 1;
    transition: all 0.2s;
    text-align: start;
    position: relative;
  }
  .tag--input {
    border: 0;
    outline: 0;
    font-size: inherit;
    line-height: inherit;
    width: 50%;
  }

  .tag--tag {
    align-items: center;
    background: var(--tag-tag);
    border-radius: var(--tag-radius);
    display: inline-flex;
    justify-content: center;
    padding: var(--tag-padding);
    button {
      background: none;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      line-height: inherit;
      padding: 0 var(--tag-s);
      &:hover {
        color: var(--tag-remove);
      }
    }
  }
  .icon-close {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.tag-close-icon {
      margin-inline-start: 3px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 10px;
      cursor: pointer;
      transition: all 0.2s;
    }
  }
`;

