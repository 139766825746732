/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { StyleInputSearch } from '../style';

type SearchProps = {
  onSearch: (value: string) => void;
  placeholder?: string;
};

const Search = ({ onSearch, placeholder }: SearchProps) => {
  const [search, setSearch] = useState('');

  const onInputChange = (value: string) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <StyleInputSearch>
      <FaSearch className="text-muted absolute left-5" />
      <input
        type="text"
        className="form-control"
        style={{ width: '500px' }}
        placeholder={placeholder ?? 'Search'}
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
      />
    </StyleInputSearch>
  );
};

export default Search;
