import styled from 'styled-components';

export const StyleCard = styled.div<{ coveralign: string }>`
  position: relative;
  // display: flex;
  // flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.625rem;
  // overflow: hidden;
  .skeleton {
    background-color: rgba(0, 0, 0, 0.06);
    &.skeleton-element {
      display: inline-block;
      width: auto;
      .skeleton-image {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: top;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        width: 100%;
        height: 100%;
        min-height: 96px;
        line-height: 96px;
        aspect-ratio: 3 / 2;
        .skeleton-image-svg {
          width: 48px;
          height: 48px;
          line-height: 48px;
          max-width: 192px;
          max-height: 192px;
          .skeleton-image-path {
            fill: #bfbfbf;
          }
        }
      }
    }
    .skeleton-header {
      display: table-cell;
      padding-inline-end: 16px;
      vertical-align: top;
    }
  }
  &.card-bordered {
    border: 1px solid #F4F4F4;
  }
  &:not(.card-bordered) {
    // box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
  &.card-hoverable {
    cursor: pointer;
    transition: box-shadow 0.2s,border-color 0.2s;
    &:hover {
      border-color: transparent;
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
  }
  &.card-side {
    display: grid;
    align-items: stretch;
    flex-direction: row;
    width: 100%;
    // gap: 1rem;
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  &.card-loading {
    .skeleton {
      .skeleton-image {
        border-radius: 0;
      }
      &.skeleton-active {
        .skeleton-title,
        .skeleton-paragraph>li,
        .skeleton-avatar,
        .skeleton-button,
        .skeleton-input,
        .skeleton-image {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
          background-size: 400% 100%;
          animation-name: skeleton-loading;
          animation-duration: 1.4s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
        }
      }
    }
    &.card-side {
      align-items: stretch;
      flex-direction: row;
      .card-body {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
      }
      .skeleton {
        &.skeleton-active {
          .skeleton-image {
            aspect-ratio: 9 / 3;
          }
        }
        .skeleton-button {
          display: inline-block;
          vertical-align: top;
          background: rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          width: 64px;
          min-width: 64px;
          height: 32px;
          line-height: 32px;
        }
        .skeleton-avatar {
          display: inline-block;
          vertical-align: top;
          background: rgba(0, 0, 0, 0.06);
          width: 32px;
          height: 32px;
          line-height: 32px;
          &.skeleton-avatar-circle {
            border-radius: 50%;
          }
        }
        .skeleton-input {
          display: inline-block;
          vertical-align: top;
          background: rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          width: 160px;
          min-width: 160px;
          height: 32px;
          line-height: 32px;
        }
        .skeleton-content {
          display: table-cell;
          width: 100%;
          vertical-align: top;
          .skeleton-paragraph {
            padding: 0;
            > li {
              width: 100%;
              height: 16px;
              list-style: none;
              background: rgba(0,0,0,.06);
              border-radius: 4px;
            }
            > li+li {
              margin-block-start: 16px;
            }
          }
        }
        &.skeleton-active {
          .skeleton-title,
          .skeleton-paragraph>li,
          .skeleton-avatar,
          .skeleton-button,
          .skeleton-input,
          .skeleton-image {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
            background-size: 400% 100%;
            animation-name: skeleton-loading;
            animation-duration: 1.4s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }
  &.glass,
  &.glass.btn-active {
    border: none;
    -webkit-backdrop-filter: blur(var(--glass-blur,40px));
    backdrop-filter: blur(var(--glass-blur,40px));
    background-color: transparent;
    background-image: linear-gradient(135deg,rgb(255 255 255/var(--glass-opacity,30%)) 0%,rgb(0 0 0/0%) 100%),linear-gradient(var(--glass-reflex-degree,100deg),rgb(255 255 255/var(--glass-reflex-opacity,10%)) 25%,rgb(0 0 0/0%) 25%);
    box-shadow: 0 0 0 1px rgb(255 255 255/var(--glass-border-opacity,10%)) inset,0 0 0 2px #0000000d;
    text-shadow: 0 1px rgb(0 0 0/var(--glass-text-shadow-opacity,5%));
  }
  &.image-full {
    display: grid;
    :where(figure) {
      overflow: hidden;
      border-radius: inherit;
    }
    &:before,
    > * {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    >.card-body {
      position: relative;
      z-index: 20;
      color: #ffffff;
    }
    &:before {
      position: relative;
      content: "";
      z-index: 10;
      background-color: #191d24;
      opacity: 0.75;
      border-radius: 0.625rem;
    }
    .card-cover {
      > * {
        display: block;
        width: auto;
        height: 100%;
      }
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .card-cover {
    margin-top: -1px;
    margin-inline-start: -1px;
    margin-inline-end: -1px;
    > * {
      display: block;
      width: 100%;
    }
    img {
      border-radius: ${props => {
        let borderRadius;
        if (props.coveralign === 'top') {
          borderRadius = '0.625rem 0.625rem 0 0';
        } else if (props.coveralign === 'right') {
          borderRadius = '0 0.625rem 0.625rem 0';
        } else if (props.coveralign === 'bottom') {
          borderRadius = '0 0 0.625rem 0.625rem';
        } else {
          borderRadius = '0.625rem 0 0 0.625rem';
        }
        return borderRadius;
      }};
    }
  }
  .card-head-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 56px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .card-header {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .card-title {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-extra {
    margin-inline-start: auto;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 14px;
  }
  &.card-small {
    >.card-head-wrapper {
      min-height: 38px;
      padding: 0 12px;
      font-size: 14px;
      >.card-extra {
        font-size: 14px;
      }
    }
    > .card-body {
      padding: 12px;
    }
  }
  &.card-contain-grid:not(.card-loading) {
    .card-body {
      margin-block-start: -1px;
      margin-inline-start: -1px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: unset;
      gap: 0;
    }
  }
  .card-grid {
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    transition: all 0.2s;
    &.card-grid-hoverable {
      &:hover {
        position: relative;
        z-index: 1;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
      }
    }
  }
  .card-body {
    padding: 24px;
    border-radius: 0 0 0.625rem 0.625rem;
    font-size: .875rem;
    line-height: 1.25rem;
    gap: 0;
    flex: none;
  }
  .card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    border-radius: 0 0 8px 8px;
    gap: 0;
    >li {
      margin: 12px 0;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      &:not(:last-child) {
        border-inline-end: 1px solid #f0f0f0;
      }
      span {
        position: relative;
        display: block;
        min-width: 28px;
        font-size: 14px;
        line-height: 1.5714285714285714;
        cursor: pointer;
        > * {
          display: inline-flex;
          align-items: center;
          color: inherit;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          vertical-align: -0.125em;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
  code {
    margin: 0 1px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 3px;
  }
`;
