import { onAuthStateChanged } from 'firebase/auth';
import Axios, { AxiosResponse, AxiosError } from 'axios';
import { firebaseAuth } from '@utils/firebase';
import { setCookie, deleteCookie, getCookie } from 'cookies-next';
import { StorageKeys } from '@data/constant';
import dayjs from 'dayjs';
import _ from 'lodash';

const baseURL = process.env.NEXT_PUBLIC_API_URL;
const axios = Axios.create({
  baseURL: `${baseURL}/`,
});

const adios = Axios.create({
  baseURL: `${baseURL}/`,
});

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error) // NOSONAR
);

axios.interceptors.response.use(
  (response): AxiosResponse => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      return error.response;
    }

    return Promise.reject(error); // NOSONAR
  }
);

adios.interceptors.request.use(
  (config) => {
    if (!config?.headers) {
      const message = `Expected 'config' and 'config.headers' not to be undefined`;
      throw new Error(message);
    }

    const savedAccessToken = getCookie(String(StorageKeys.AccessToken));

    if (savedAccessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${savedAccessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error) // NOSONAR
);

adios.interceptors.response.use(
  (response): AxiosResponse => response,
  async ({ response, config: originalRequest }: AxiosError) => {
    const httpStatus = response?.status;
    // @ts-ignore
    if (httpStatus === 452 && !originalRequest._retry) {
      // @ts-ignore
      originalRequest._retry = true;

      // const { accessToken } = await refreshToken();
      // if (accessToken) {
      //   // @ts-ignore
      //   config.headers = {
      //     // @ts-ignore
      //     ...config.headers,
      //     authorization: `Bearer ${accessToken}`,
      //   };
      // }
      // @ts-ignore
      return await adios(originalRequest);
    } else if (
      httpStatus === 401 ||
      httpStatus === 406 ||
      // @ts-ignore
      (httpStatus === 403 && !originalRequest._retry)
    ) {
      // @ts-ignore
      originalRequest._retry = true;
      // @ts-ignore
      if (response?.data?.message === 'The email address is already in use by another account.') {
        // @ts-ignore
        return Promise.reject(response); // NOSONAR
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAuthStateChanged(firebaseAuth, (user: any) => {
          if (user !== null) {
            // @ts-ignore
            originalRequest.headers.Authorization = `Bearer ${user?.accessToken}`;
            const accessToken = _.get(user?.accessToken, 'access_token');
            const refresh = _.get(user.refreshToken, 'refresh_token');
            const exp_accessToken = dayjs(_.get(refresh, 'exp'))
              .subtract(20, 'minute')
              .unix();
            const exp_refreshToken = dayjs(_.get(refresh, 'exp'))
              .subtract(20, 'minute')
              .unix();
            setCookie(String(StorageKeys.ExpAccessToken), exp_accessToken);
            setCookie(String(StorageKeys.ExpRefreshToken), exp_refreshToken);
            setCookie(String(StorageKeys.AccessToken), accessToken);
            setCookie(String(StorageKeys.RefreshToken), refresh);
            // @ts-ignore
            return adios(originalRequest);
          } else {
            deleteCookie(String(StorageKeys.ExpAccessToken));
            deleteCookie(String(StorageKeys.ExpRefreshToken));
            deleteCookie(String(StorageKeys.AccessToken));
            deleteCookie(String(StorageKeys.RefreshToken));
            deleteCookie(String(StorageKeys.User));
            deleteCookie(String(StorageKeys.UserImg));
            window.location.href = '/login';
          }
        });
      }
    } else if (!response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(new Error(JSON.stringify({
        code: 'NOT_CONNECT_NETWORK',
        message: 'NOT CONNECT NETWORK',
      })));
    }
    return Promise.reject(response?.data); // NOSONAR
  }
);

const instance = adios;

export { axios, instance };
