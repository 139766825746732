/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { StyleInputNumber } from './style';
import classNames from 'classnames';
import { checkNumber, checkRealNumber } from '@/enum/regexp';
import type { Meta } from 'rc-field-form/lib/interface';

const InputNumber: React.FC<{
  type?: 'number' | 'realnumber'
  size?: 'lg'
  className?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  autoComplete?: string
  state?: 'success' | 'warning' | 'error'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  texthelp?: string
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  meta?: Meta
  rootClassName?: string
}> = ({
  type = 'number',
  size = '',
  className,
  placeholder,
  value,
  disabled,
  onChange,
  autoComplete,
  state,
  texthelp,
  prefix,
  suffix,
  meta,
  rootClassName,
}) => {
  const [valueInputNumber, setValueInputNumber] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    switch (type) {
      case 'realnumber':
        if (value === '' || checkRealNumber.test(value)) {
          if (onChange) {
            onChange(e);
          } else {
            setValueInputNumber(value);
          }
        }
        break;
      case 'number':
        if (value === '' || checkNumber.test(value)) {
          if (onChange) {
            onChange(e);
          } else {
            setValueInputNumber(value);
          }
        }
        break;
      default:
        break;
    }
  };

  const rootCls = classNames(
    {
      disabled: disabled === true,
    },
    rootClassName,
  );

  const inputCls = classNames({
    [`input-state input-state-${state}`]: state !== undefined,
    [`input-error`]: meta !== undefined && meta.errors.length > 0,
    [`input-${size}`]: size === 'lg',
    [`input-default`]: size === undefined,
    [`input-has-suffix`]: suffix !== undefined,
    [`input-has-prefix`]: prefix !== undefined,
  }, className);

  const chkState = (state: string | undefined) => {
    if (state === 'success') {
      return <FaCheckCircle className='absolute right-5 text-success' />;
    } else if (state === 'warning') {
      return <FaInfoCircle className='absolute right-5 text-warning' />;
    } else if (state === 'error') {
      return <FaTimesCircle className='absolute right-5 text-error' />;
    } else {
      return '';
    }
  };

  return (
    <StyleInputNumber className={rootCls}>
      {prefix && (
        <span className='input-prefix'>
          <span className='input-prefix-icon'>
            {prefix}
          </span>
        </span>
      )}
      {type === 'realnumber' ? (
        <input
          disabled={disabled}
          defaultValue={valueInputNumber}
          // defaultValue={value}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'e') {
              e.preventDefault();
            }
          }}
          type='text'
          placeholder={placeholder}
          className={inputCls}
          autoComplete={autoComplete}
        />
      ) : (
        <input
          disabled={disabled}
          defaultValue={valueInputNumber}
          // defaultValue={value}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'e') {
              e.preventDefault();
            }
          }}
          type='number'
          placeholder={placeholder}
          className={inputCls}
          autoComplete={autoComplete}
        />
      )} 
      {suffix && (
        <span className='input-suffix'>
          <span className='input-suffix-icon'>
            {suffix}
          </span>
        </span>
      )}
      {state && (
        <span className='input-state input-suffix'>
          <span className='input-suffix-icon'>
            {chkState(state)}
          </span>
        </span>
      )}
      {texthelp !== undefined ? <div className='text-muted mt-1 text-sm'>{texthelp}</div> : ''}
    </StyleInputNumber>
  );
};

export default InputNumber;
