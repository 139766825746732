/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Dropdown from '../Dropdown';
import type { ItemType } from './Breadcrumb';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import { renderItem } from './useItemRender';
import { FaAngleDown } from 'react-icons/fa';

export interface SeparatorType {
  separator?: React.ReactNode;
  key?: React.Key; // NOSONAR
}

export interface BreadcrumbItemProps extends SeparatorType {
  href?: string; // NOSONAR
  contentmenu?: React.ReactNode;
  className?: string; // NOSONAR
  children?: React.ReactNode;
}

export const InternalBreadcrumbItem: React.FC<BreadcrumbItemProps> = (
  props,
) => {
  const { separator = '/', children, contentmenu } = props;

  const renderBreadcrumbNode = (breadcrumbItem: React.ReactNode) => {
    if (contentmenu) {
      return (
        <Dropdown
          trigger={
            <span className="dropdown-overlay-link">
              {breadcrumbItem}
              <FaAngleDown />
            </span>
          }
          content={contentmenu}
          placement="bottom"
          animation={true}
        />
      );
    }
    return breadcrumbItem;
  };

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const link = renderBreadcrumbNode(children);

  if (link) {
    return (
      <>
        <li>{link}</li>
        {separator && <BreadcrumbSeparator>{separator}</BreadcrumbSeparator>}
      </>
    );
  }

  return null;
};

type CompoundedComponent = React.FC<BreadcrumbItemProps> & {
  __ANT_BREADCRUMB_ITEM: boolean;
};

const BreadcrumbItem: CompoundedComponent = (props) => {
  const { children, href, ...restProps } = props;
  const prefixCls = 'breadcrumb';

  return (
    <InternalBreadcrumbItem {...restProps}>
      {renderItem(prefixCls, restProps as ItemType, children, href)}
    </InternalBreadcrumbItem>
  );
};

BreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default BreadcrumbItem;
