/* eslint-disable testing-library/render-result-naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Field } from 'rc-field-form';
import type { Meta, FormInstance, Rule } from 'rc-field-form/lib/interface';
import type {
  InternalNamePath,
  NamePath,
  EventArgs,
  StoreValue,
} from './interface';
import classNames from 'classnames';

type ErrorProps = {
  children: string[];
};

interface ChildProps {
  [name: string]: any;
}

export type ShouldUpdate<Values = any> =
  | boolean
  | ((
      prevValues: Values,
      nextValues: Values,
      info: { source?: string },
    ) => boolean);

type LabelProps<Values = any> = {
  colon?: boolean;
  name?: string | InternalNamePath;
  label?: string;
  labelHidden?: boolean;
  isCheckbox?: boolean;
  norequired?: boolean;
  className?: string;
  extra?: React.ReactNode;
  children?:
    | React.ReactElement
    | ((
        control: ChildProps,
        meta: Meta,
        form: FormInstance<Values>,
      ) => React.ReactNode);
  hasFeedback?: boolean;
  rules?: Rule[];
  dependencies?: NamePath[];
  getValueFromEvent?: (...args: EventArgs) => StoreValue;
  initialValue?: any;
  customRequestText?: string;
};

const ErrorMessage = ({ children }: ErrorProps) => (
  <>
    {children.map((error) => (
      <span className="label-text-alt text-error" key={error}>
        {error}
      </span>
    ))}
  </>
);

function renderChildNode(
  children: LabelProps['children'],
  control: ChildProps,
  meta: Meta,
  form: FormInstance<string | InternalNamePath>,
  restProps: LabelProps,
): React.ReactNode {
  if (typeof children === 'function') {
    return children(control, meta, form);
  }
  return React.cloneElement(children as React.ReactElement, {
    meta,
    'data-name': restProps.name,
    ...control,
  });
}

const Label = (props: LabelProps) => {
  const {
    name,
    label,
    colon: contextColon = true,
    labelHidden = false,
    isCheckbox,
    children,
    norequired = false,
    className = '',
    extra,
    hasFeedback,
    initialValue,
    customRequestText,
    ...restProps
  } = props;

  let labelColon: React.ReactNode = '';

  // Keep label is original where there should have no colon
  const computedColon = contextColon === true || contextColon !== false;
  const haveColon = computedColon;

  // Remove duplicated user input colon
  if (haveColon && (label as string).trim() !== '') {
    labelColon = '：';
  }

  const labelClassName = classNames('label', {
    [`label-item-nonerequired`]: norequired,
    [`label-item-no-colon`]: !computedColon,
    [`sr-only`]: labelHidden,
  });

  let requestText = '(ไม่จำเป็นต้องระบุ)';
  if (norequired && customRequestText) {
    requestText = customRequestText;
  }

  return (
    <Field name={name} initialValue={initialValue} {...restProps}>
      {(
        control: ChildProps,
        meta: Meta,
        form: FormInstance<string | InternalNamePath>,
      ): React.ReactNode => {
        const childNode = renderChildNode(
          children,
          control,
          meta,
          form,
          restProps,
        );

        return (
          <div
            className={`form-control ${
              isCheckbox
                ? `form-check flex w-full flex-row items-center space-x-2 ${className}`
                : className
            }`}
          >
            {label && !isCheckbox && (
              <label
                className={labelClassName}
                htmlFor={Array.isArray(name) ? name.join('-') : name ?? ''}
              >
                <span className={`label-text ${label ? '' : 'sr-only'}`}>
                  {(label as string).replace(/[:|：]\s*$/, '') ?? name}{' '}{/* NOSONAR*/}
                </span>
                {norequired && (
                  <span className="text-black">{requestText}</span>
                )}
                {labelColon}
              </label>
            )}

            {isCheckbox && label && (
              <>
                {childNode}
                <label
                  className={labelClassName}
                  htmlFor={Array.isArray(name) ? name.join('-') : name ?? ''}
                >
                  <span className={`label-text ${label ? '' : 'sr-only'}`}>
                    {(label as string).replace(/[:|：]\s*$/, '') ?? name}{' '}{/* NOSONAR*/}
                  </span>
                  {norequired && (
                    <span className="text-black">{requestText}</span>
                  )}
                  {labelColon}
                </label>
              </>
            )}

            {label ? childNode : null}

            {meta.errors.length > 0 && (
              <div className="label flex flex-col items-start gap-1">
                <ErrorMessage>{meta.errors}</ErrorMessage>
              </div>
            )}
            {extra && <div className="small text-muted">{extra}</div>}
          </div>
        );
      }}
    </Field>
  );
};

export default Label;
