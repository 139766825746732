import styled from 'styled-components';

export const StyleModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &.fade {
    transition: opacity .15s linear;
    .modal-dialog {
      transition: transform .3s ease-out;
      transform: translate(0,-50px);
    }
  }
  &.show {
    display: block;
    .modal-dialog {
      transform: none;
    }
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 1.75rem;
    pointer-events: none;
    @media only screen and (min-width: 576px) {
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
    }
    &.modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - 1.75rem * 2);
    }
    &.modal-dialog-scrollable {
      height: calc(100% - 1.75rem) * 2);
    }
    &.modal-sm {
      max-width: 300px;
    }
    &.modal-lg {
      max-width: 800px;
    }
    &.modal-xl {
      max-width: 1140px;
    }
    &.modal-fullscreen {
      max-width: 100%;
      height: 100%;
    }
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0.5rem;
    outline: 0;
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
  }
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    // border-bottom: 1px solid #495057;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      text-transform: capitalize;
      font-size: 1.25rem;
    }
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(1rem - 0.5rem * .5);
    background-color: #ffffff;
    // border-top: 1px solid #495057; // #adb5bd
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`;

export const StyleBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  &.fade {
    transition: opacity .15s linear;
  }
  &.show {
    display: block;
  }
`;