import styled from 'styled-components';

export const StyleInputNumber = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
  input {
    width: 100%;
    flex-shrink: 1;
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-width: 1px;
    // border-color: #D7D8D9;
    border-radius: 14px;
    &:focus {
      outline-offset: 0px;
    }
    &.input-lg {
      height: 3.5rem;
      min-height: 3.5rem;
    }
    &.input-state-warning,
    &.input-state-error,
    &.input-state-success {
      padding-right: 3.25rem;
      border-width: 2px;
      border-radius: calc(0.625rem - 2px);
    }
    &.input-warning {
      border-color: hsl(var(--wa));
    }
    &.input-error {
      border-color: #FF2D49;
    }
    &.input-success {
      border-color: hsl(var(--su));
    }
    &.input-info {
      border-color: hsl(var(--in));
    }
  }
  .input-state {
    &.input-suffix {
      position: absolute;
      width: 3.25rem;
      height: 3rem;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      &.input-prefix {
        left: 1.25rem;
        right: auto;
      }
      .input-suffix-icon {
        font-size: 1rem;
        line-height: 1.5rem;
        color: #ADABB9;
        display: flex;
        align-items: center;
      }
    }
  }
  .input-suffix {
    position: absolute;
    // right: 1.25rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: auto;
    width: 1.25rem;
    &.input-prefix {
      left: 1.25rem;
      right: auto;
    }
    .input-suffix-icon {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #ADABB9;
    }
  }
  .input-suffix-password {
    cursor: pointer;
    .input-suffix-icon {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  .input-prefix {
    position: absolute;
    left: 1.25rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: auto;
    .input-prefix-icon {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #ADABB9;
    }
  }
`;