import React, { ForwardedRef, forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { dayOfTheWeekOf, firstDateOfMonth } from './date';
import { ButtonClear, ButtonNextMonth, ButtonPrevMonth, ButtonSelectMonth, ButtonToday } from './Buttons';
import { DatePickerContext } from './DatePickerProvider';
import Days from './Views/Days';
import Decades from './Views/Decades';
import Months from './Views/Months';
import Years from './Views/Years';
import TimeField from './TimeField';

const DatePickerPopup = forwardRef<HTMLDivElement>(
  (_props, ref: ForwardedRef<HTMLDivElement>) => {
    const { selectedDate, selectedMonth, selectedYear, view, options, setSelectedTime } =
      useContext(DatePickerContext);

    const language = options?.language ? options?.language : 'en';
    const locale = new Intl.Locale(language);
    const [time, setTime] = React.useState<Date>(selectedDate);

    // @ts-ignore
    const weekStart = locale?.weekInfo?.firstDay || 1;
    const firstOfMonth = firstDateOfMonth(selectedYear, selectedMonth, 1);
    const start = dayOfTheWeekOf(firstOfMonth, weekStart, weekStart);

    const updateTime = (origDate: Date, newHour: number, newMinute: number) => {
      const date = new Date(origDate);
      date.setHours(newHour);
      date.setMinutes(newMinute);

      // If Past Max Date Dont allow update
      if (options?.maxDate && date > options.maxDate) {
        return false;
      }

      return setTime(date);
    };

    const timeChangeCallback = (newHour: number, newMinute: number) => {
      const updatedTime = selectedDate;
      updatedTime.setHours(newHour);
      updatedTime.setMinutes(newMinute);
      setTime(updatedTime);
      updateTime(time, newHour, newMinute);
      setSelectedTime(updatedTime);
    };

    const handleTimeChange = (newTime: Date) => {
      setTime(newTime);
    };

    return (
      <div
        ref={ref}
        className={classnames(options?.datepickerClassNames)}
      >
        <div
          className={classnames(
            'inline-block p-4 bg-white rounded-lg shadow-lg',
            options?.theme?.background
          )}
        >
          <div>
            {options?.title && (
              <div
                className={classnames(
                  'px-2 py-3 font-semibold text-center text-gray-900',
                  options?.theme?.text
                )}
              >
                {options?.title}
              </div>
            )}
            <div className="flex justify-between mb-2">
              <ButtonPrevMonth />
              <ButtonSelectMonth />
              <ButtonNextMonth />
            </div>
          </div>
          <div className="p-1">
            {view === 'days' && <Days start={start} />}
            {view === 'months' && <Months />}
            {view === 'years' && <Years />}
            {view === 'decades' && <Decades />}
          </div>
          {options?.showTime && (
            <div className='mt-2 w-full space-x-2'>
              <TimeField
                date={time}
                timeChangeCallback={timeChangeCallback}
                twelveHoursClock={options?.twelveHoursClock}
                timeClassNames={options?.timeClassNames}
                handleTimeChange={handleTimeChange}
              />
            </div>
          )}
          {(options?.todayBtn || options?.clearBtn) && (
            <div className='mt-2 flex space-x-2'>
              {options?.todayBtn && <ButtonToday />}
              {options?.clearBtn && <ButtonClear />}
            </div>
          )}
        </div>
      </div>
    );
  }
);
DatePickerPopup.displayName = 'DatePickerPopup';

export default DatePickerPopup;
