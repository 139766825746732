import styled from 'styled-components';

export const StyleProgress = styled.div<{ value: number }>`
  --nextui--gridGapUnit: calc(2 * 0.375rem);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: calc(-1 * var(--nextui--gridGapUnit));
  width: calc(100% + var(--nextui--gridGapUnit) * 2);
  &.progress-wrapper {
    margin: 0px;
    box-sizing: border-box;
    padding: calc(2 * 0.375rem) 0;
    // margin: calc(2 * 0.375rem) 0;
    width: 100%;
    height: auto;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    .progress-wrapper-enter {
      margin: 0px;
      padding: 0px;
      width: ${props => props.value ? `${props.value}%` : '0'};
      opacity: 0;
      height: 100%;
      min-width: inherit;
      border-radius: inherit;
      transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      &.progress-wrapper-enter-active {
        opacity: 1;
      }
      &.striped {
        background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent);
        background-size: 1.25rem 1.25rem;
        animation: 1s linear 0s infinite normal none running stripes;
        &.indeterminated-true {
          position: absolute;
          width: 0%;
          transition-property: background-color, width, left, border-color, opacity, shadow, transform;
          transition-duration: 300ms;
          will-change: left;
          min-width: ${props => props.value ? `${props.value}%` : '50%'};
          animation: 1s ease 0s infinite normal none running nextui-k-itSsug;
        }
      }
      &.indeterminated-true {
        position: absolute;
        width: 0%;
        transition-property: background-color, width, left, border-color, opacity, shadow, transform;
        transition-duration: 300ms;
        will-change: left;
        min-width: ${props => props.value ? `${props.value}%` : '50%'};
        animation: 1s ease 0s infinite normal none running nextui-k-itSsug;
      }
    }
    .progressbar {
      margin: 0px;
      padding: 0px;
      width: 100%;
      position: relative;
      overflow: hidden;
      &.progressbar-color-primary {
        --nextui--progressColor: #0072F5;
        --nextui--progressShadowColor: #0952A5;
      }
      &.progressbar-status-default {
        // background: #2B2F31;
        background: rgba(0, 0, 0, 0.06);
      }
      .progressbar-text {
        position: relative;
        color: #ffffff;
        font-size: 0.775rem;
        font-weight: 500;
        line-height: 1.25;
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: block;
        padding: 0 10px;
      }
      &.shadow {
        box-shadow: 0 4px 14px 0 #0072F5;
      }
      &.progressbar-size-md {
        --nextui--progressHeight: 1rem;
        height: var(--nextui--progressHeight);
        border-radius: var(--nextui--progressHeight);
        .progressbar-text {
          font-size: 0.775rem;
          line-height: 1.25;
        }
      }
      &.progressbar-size-xs {
        --nextui--progressHeight: 0.25rem;
        height: var(--nextui--progressHeight);
        border-radius: var(--nextui--progressHeight);
        .progressbar-text {
          font-size: 0.775rem;
          line-height: 1.25;
        }
      }
      &.progressbar-size-sm {
        --nextui--progressHeight: 0.5rem;
        height: var(--nextui--progressHeight);
        border-radius: var(--nextui--progressHeight);
        .progressbar-text {
          font-size: 0.775rem;
          line-height: 1.25;
        }
      }
      &.progressbar-size-lg {
        --nextui--progressHeight: 1.5rem;
        height: var(--nextui--progressHeight);
        border-radius: var(--nextui--progressHeight);
        .progressbar-text {
          font-size: 0.875rem;
          line-height: 1.5;
        }
      }
      &.progressbar-size-xl {
        --nextui--progressHeight: 1.75rem;
        height: var(--nextui--progressHeight);
        border-radius: var(--nextui--progressHeight);
        .progressbar-text {
          font-size: 1.15rem;
          line-height: 1.5;
        }
      }
    }
  }
  @keyframes nextui-k-itSsug {
    0% {
      left: -40%;
    },
    100% {
      left: 100%;
    }
  }
  @keyframes stripes {
    0% {
      background-position: 1rem 0;
    },
    100% {
      background-position: 0 0;
    }
  }
`;