/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button } from '@components/elements';
import classNames from 'classnames';
import { StyleModal, StyleBackDrop } from './style';
import Portal from './Portal';
import type { ModalProps } from './interface';
import { FaTimes } from 'react-icons/fa';

const Modal = (props: ModalProps) => {
  const {
    children,
    isOpen,
    setIsOpen,
    title,
    footer,
    clickOutClose = false,
    contentClassName,
    rootClassName,
    isCentered = false,
    size,
    onOk,
    onCancel,
    textCancel = 'Cancel',
    textOk = 'Ok',
  } = props;

  const sizeCls = classNames('modal-dialog modal-dialog-scrollable', {
    'modal-sm': size === 'sm',
    'modal-lg': size === 'lg',
    'modal-xl': size === 'xl',
    'modal-fullscreen': size === 'fullscreen',
    'modal-dialog-centered': isCentered === true,
  }, rootClassName);

  const handleCancel = () => {
    if (onCancel) {
      onCancel; // NOSONAR
    }
    setIsOpen(false);
  };

  return (
    <Portal>
      <StyleModal
        className={`${isOpen === true ? ' fade show' : ' fade'}`}
        id="staticBackdrop"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className={sizeCls}>
          <div className={classNames('modal-content', {"overflow-hidden": title !== undefined})}>
            {title === undefined && (
              <div
                data-bs-dismiss="modal"
                aria-label="Close"
                aria-hidden="true" 
                className="absolute -top-2.5 -right-2.5 flex items-center justify-center bg-error/90 p-2 rounded-full z-10 cursor-pointer"
                onClick={handleCancel}
              >
                <FaTimes size={16} className='text-white' />
              </div>
            )}
            {title && (
              <div className="modal-header">
                <h1 className="modal-title" id="staticBackdropLabel">
                  {title}
                </h1>
                <button
                  type="button"
                  className="btn bg-transparent hover:bg-transparent mt-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCancel}
                >&#10006;</button>
              </div>
            )}
            <div className={classNames('modal-body', contentClassName)}>{children}</div>
            {footer !== null && (
              <div className="modal-footer space-x-2">
                <Button
                  htmlType="button"
                  color="secondary"
                  className="align-center"
                  onClick={handleCancel}
                >
                  {textCancel}
                </Button>
                <Button
                  htmlType="submit"
                  color="primary"
                  className="align-center"
                  onClick={onOk}
                >
                  {textOk}
                </Button>
              </div>
            )}
          </div>
        </div>
      </StyleModal>
      {clickOutClose === true ? (
        <StyleBackDrop
          className={
            isOpen === true ? 'backdrop fade show' : 'backdrop fade'
          }
          onClick={() => {
            setIsOpen(false);
          }}
        ></StyleBackDrop>
      ) : (
        <StyleBackDrop
          className={
            isOpen === true ? 'backdrop fade show' : 'backdrop fade'
          }
        ></StyleBackDrop>
      )}
    </Portal>
  );
};

export default Modal;

