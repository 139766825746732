import dayjs from 'dayjs';
import { Mode } from './TimeField';

export const generateHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(i);
  }
  return hours;
};

export const generateMinutes = () => {
  const minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push(`0${i.toString()}`);
    } else {
      minutes.push(i.toString());
    }
  }
  return minutes;
};

function workOutMonthYear(
  date: Date,
  secondDate: Date,
  mode?: Mode,
  pastSearchFriendly?: boolean,
  smartMode?: boolean
) {
  // If both months are different months then
  // allow normal display in the calendar
  if (dayjs(date).month() !== dayjs(secondDate).month()) {
    return date;
  }
  // If pastSearch Friendly mode is on and both months are the same and the same year
  // have "end"/right as the month and "start"/left as -1 month
  else if (
    dayjs(date).year() === dayjs(secondDate).year() &&
    mode === 'start' &&
    pastSearchFriendly &&
    smartMode
  ) {
    const lastMonth = dayjs(date).subtract(1, 'month').toDate();
    return lastMonth;
  }
  // If pastSearch Friendly mode is off and both months are the same and the same year
  // have "end"/right as the month and "start"/left as +1 month
  else if (
    dayjs(date).year() === dayjs(secondDate).year() &&
    mode === 'end' &&
    !pastSearchFriendly &&
    smartMode
  ) {
    const lastMonth = dayjs(date).add(1, 'month').toDate();
    return lastMonth;
  } else {
    return date;
  }
}

export const getMonth = (
  date: Date,
  secondDate: Date,
  mode?: Mode,
  pastSearchFriendly?: boolean,
  smartMode?: boolean
) =>
  dayjs(workOutMonthYear(date, secondDate, mode, pastSearchFriendly, smartMode)).month();

export const getYear = (
  date: Date,
  secondDate: Date,
  mode?: Mode,
  pastSearchFriendly?: boolean,
  smartMode?: boolean
) =>
  dayjs(workOutMonthYear(date, secondDate, mode, pastSearchFriendly, smartMode)).year();

const getDaysBeforeStartMonday = (firstDayOfMonth: Date) => {
  const fortyTwoDays = [];
  const dayBeforeFirstDayOfMonth = dayjs(firstDayOfMonth).day() - 1; // We dont want to include the first day of the new month
  // Case whereby day before is a Saturday (6) and we require Saturday back to Monday for that week
  if (dayBeforeFirstDayOfMonth === -1) {
    for (let i = 6; i > 0; i--) {
      const previousDay = dayjs(firstDayOfMonth).subtract(i, 'day').toDate();
      fortyTwoDays.push(previousDay);
    }
  }
  // Case Whereby day before first day is the Sunday (0), therefore we want the entire previous week
  if (dayBeforeFirstDayOfMonth === 0) {
    for (let i = 7; i > 0; i--) {
      const previousDay = dayjs(firstDayOfMonth).subtract(i, 'day').toDate();
      fortyTwoDays.push(previousDay);
    }
  }
  // Every other day
  else {
    for (let i = dayBeforeFirstDayOfMonth; i > 0; i--) {
      const previousDay = dayjs(firstDayOfMonth).subtract(i, 'day').toDate();
      fortyTwoDays.push(previousDay);
    }
  }
  return fortyTwoDays;
};

const getDaysBeforeStartSunday = (firstDayOfMonth: Date) => {
  const fortyTwoDays = [];
  const dayBeforeFirstDayOfMonth = dayjs(firstDayOfMonth).day() - 1; // We dont want to include the first day of the new month

  // Case whereby we need all previous week days
  if (dayBeforeFirstDayOfMonth === -1) {
    for (let i = 7; i > 0; i--) {
      for (let i = 7; i > 0; i--) {
        const previousDay = dayjs(firstDayOfMonth).subtract(i, 'day').toDate();
        fortyTwoDays.push(previousDay);
      }
    }
  }
  // Every other day
  else {
    for (let i = dayBeforeFirstDayOfMonth + 1; i > 0; i--) {
      const previousDay = dayjs(firstDayOfMonth).subtract(i, 'day').toDate();
      fortyTwoDays.push(previousDay);
    }
  }
  return fortyTwoDays;
};

const getDaysBeforeStart = (firstDayOfMonth: Date, sundayFirst: boolean) => {
  if (!sundayFirst) {
    return getDaysBeforeStartMonday(firstDayOfMonth);
  } else {
    return getDaysBeforeStartSunday(firstDayOfMonth);
  }
};

export const getFourtyTwoDays = (
  initMonth: number,
  initYear: number,
  sundayFirst: boolean
) => {
  let fourtyTwoDays = [];
  const firstDayOfMonth = dayjs(new Date(initYear, initMonth, 1)).startOf('month').toDate();
  const lastDayOfMonth = dayjs(firstDayOfMonth).endOf('month').toDate();

  fourtyTwoDays = getDaysBeforeStart(firstDayOfMonth, sundayFirst);
  // Add in all days this month
  for (let i = 0; i < dayjs(firstDayOfMonth).daysInMonth(); i++) {
    fourtyTwoDays.push(dayjs(firstDayOfMonth).add(i, 'day').toDate());
  }
  // Add in all days at the end of the month until last day of week seen
  let toAdd = 1;
  let gotAllDays = false;
  while (!gotAllDays) {
    if (fourtyTwoDays.length >= 42) {
      gotAllDays = true; // NOSONAR
      break;
    }
    fourtyTwoDays.push(dayjs(lastDayOfMonth).add(toAdd, 'day').toDate());
    toAdd++;
  }
  return fourtyTwoDays;
};

export const isInbetweenDates = (
  isStartDate: boolean,
  dayToFindOut: Date,
  start: Date,
  end: Date
) => {
  let isInBetweenDates;

  if (isStartDate) {
    isInBetweenDates =
      dayjs(dayToFindOut).isAfter(start) && dayjs(dayToFindOut).isBefore(end);
  } else {
    isInBetweenDates =
      dayjs(dayToFindOut).isBefore(start) && dayjs(dayToFindOut).isAfter(end);
  }

  return isInBetweenDates;
};

export const isValidTimeChange = (
  mode: Mode,
  date: Date,
  start: Date,
  end: Date
) => {
  const modeStartAndDateSameOrBeforeStart =
    mode === 'start' && (dayjs(date).isBefore(end) || dayjs(date).isSame(end));
  const modeEndAndDateSameOrAfterEnd =
    mode === 'end' && (dayjs(date).isAfter(start) || dayjs(date).isSame(start));
  return modeStartAndDateSameOrBeforeStart || modeEndAndDateSameOrAfterEnd;
};
