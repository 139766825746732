import React, { CSSProperties, ReactNode } from 'react';
import { StyleEmpty } from './style';
import EmptyIcon from './emptyIcon';

type EmptyProps = {
  imageStyle?: CSSProperties;
  image?: React.JSX.Element | string;
  description?: ReactNode;
  children?: ReactNode;
};

const defaultEmptyImg = <EmptyIcon />;

function Empty(props: Readonly<EmptyProps>) {
  const { image = defaultEmptyImg, imageStyle, description, children } = props;
  const des = typeof description !== 'undefined' ? description : 'No data';
  const alt = typeof des === 'string' ? des : 'empty';
  let imageNode: ReactNode = null;

  if (typeof image === 'string') {
    imageNode = <img alt={alt} src={image} />;
  } else {
    // @ts-ignore
    imageNode = image;
  }

  return (
    <StyleEmpty>
      {imageNode && (
        <div className="empty-image" style={imageStyle}>
          {imageNode}
        </div>
      )}
      {des && <div className="">{des}</div>}
      {children && <div className="">{children}</div>}
    </StyleEmpty>
  );
};

Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;


export default Empty;