import classNames from 'classnames';
import * as React from 'react';

export interface SkeletonElementProps {
  prefixCls?: string;
  className?: string;
  // NOSONAR
  rootClassName?: string;
  style?: React.CSSProperties;
  size?: 'large' | 'small' | 'default' | number;
  shape?: 'circle' | 'square' | 'round' | 'default';
  // NOSONAR
  active?: boolean;
}

const Element: React.FC<SkeletonElementProps> = (props) => {
  const { prefixCls, className, style, size, shape, active, rootClassName } = props;

  const sizeCls = classNames({
    [`skeleton-lg`]: size === 'large',
    [`skeleton-sm`]: size === 'small'
  });

  const shapeCls = classNames({
    [`skeleton-circle`]: shape === 'circle',
    [`skeleton-square`]: shape === 'square',
    [`skeleton-round`]: shape === 'round',
    [`skeleton-active`]: active,
  });

  const sizeStyle = React.useMemo<React.CSSProperties>(
    () =>
      typeof size === 'number'
        ? {
            width: size,
            height: size,
            lineHeight: `${size}px`
          }
        : {},
    [size]
  );

  return (
    <span
      className={classNames(prefixCls, sizeCls, shapeCls, className, rootClassName)}
      style={{ ...sizeStyle, ...style }}
    />
  );
};

export default Element;
