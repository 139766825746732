import styled from 'styled-components';

export const StyledDropdownContainer = styled.div`
  &.dropdown {
    position: relative;
    cursor: pointer;
  }

  .trigger {
    cursor: pointer;
  }

  .content {
    position: fixed;
    background-color: #fff;
    padding: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 10;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 14px;
    box-shadow: 0px 4px 7px 0px #00000021;
    display: none;
    &.open {
      opacity: 1;
      display: block;
    }
    > * + * {
      padding: 0.25rem 0.5rem;
    }
  }
  .dropdown-item {
    cursor: pointer;
    color: #504E66;
    font-size: 0.875rem;
    line-height: 2.55rem;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 14px;
    &:hover {
      background-color: #DFEFFF;
    }
  }
`;
