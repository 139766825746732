import { StyleRadio } from './style';
import classNames from 'classnames';

export type colorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | undefined
  | null;

interface RadioProps {
  wrapperClassName?: string;
  className?: string;
  label: string;
  checked?: boolean;
  onChange?: () => Promise<void> | void;
  disabled?: boolean;
  id: string;
  value?: string | number;
  name: string;
  color?: colorType;
}

const RadioButton = ({
  id,
  value,
  name,
  wrapperClassName,
  className,
  label,
  checked,
  onChange,
  disabled,
  color,
}: RadioProps) => {
  const cls = classNames(
    'radio',
    {
      [`radio-primary`]: color === 'primary',
      [`radio-secondary`]: color === 'secondary',
      [`radio-success`]: color === 'success',
      [`radio-error`]: color === 'error',
      [`radio-warning`]: color === 'warning',
      [`radio-info`]: color === 'info',
      [`radio-light`]: color === 'light',
      [`radio-dark`]: color === 'dark',
    },
    className,
  );

  const clsWrapper = classNames('form-check-custom form-check-solid', wrapperClassName);

  return (
    <StyleRadio className={clsWrapper}>
      <input
        className={cls}
        type="radio"
        value={value}
        disabled={disabled}
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </StyleRadio>
  );
};

export default RadioButton;
