import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { StyleNavs } from './style';
import { useRouter } from 'next/router';

export type routeProps = {
  title: string;
  href: string;
  as?: string;
  disabled?: boolean;
  itemClass?: string;
};

export interface NavsProps {
  navAlign?: 'start' | 'center' | 'end';
  layout?: 'horizontal' | 'vertical';
  route: routeProps[];
}

const Navs = (props:NavsProps) => {
  const {
    navAlign = 'start',
    layout = 'horizontal',
    route,
  } = props;
  const router = useRouter();
  const prefixCls = 'nav';
  const cls = classNames(
    prefixCls,
    {
      [`nav-${layout}`]: layout,
      [`flex-column`]: layout === 'vertical',
      ['justify-center']: navAlign === 'center',
      ['justify-end']: navAlign === 'end',
    }
  );

  return (
    <StyleNavs className={cls}>
      {route && route.length > 0 && 
        route.map((route) => {
          const isActive = router.asPath === route.href;
          const isDisabled = route.disabled;
          const classStr = classNames(
            'nav-link',
            route.itemClass,
            {
              ['active']: isActive,
              ['disabled']: isDisabled,
            }
          );

          return (
            <li className="nav-item" key={route.href}>
              <Link href={route.href} className={classStr}>
                {route.title}
              </Link>
            </li>
          );
        })}
    </StyleNavs>
  );
};

export default Navs;