import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import 'dayjs/locale/th';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);
dayjs.tz.setDefault('Asia/Bangkok');
dayjs.locale('th');

interface DateRenderProps {
  type?: 'short' | 'long';
  showTime?: boolean;
  date: Dayjs | string | Date | number;
  dateSeparator?: string;
  localTh?: boolean;
}

const DateRender = ({
  type,
  showTime,
  date: dat,
  dateSeparator = '/',
  localTh = true,
}: DateRenderProps) => {
  let years = 'YYYY';
  if (localTh) {
    years = 'BBBB';
  }
  const checkTypeDate = typeof dat === 'number' ? dayjs.unix(dat) : dayjs(dat);
  if (!checkTypeDate.isValid()) return null;

  const renderDate = (type: 'short' | 'long' | undefined) => {
    switch (type) {
      case 'short':
        return checkTypeDate.format(
          `DD${dateSeparator}MM${dateSeparator}${years}${
            showTime === true ? ' - HH:mm:ss' : ''
          }`
        );
      case 'long':
        return checkTypeDate.format(
          `DD MMMM ${years}${showTime === true ? ' - HH:mm:ss' : ''}`
        );
      default:
        return checkTypeDate.format(
          `DD MMM ${years}${showTime === true ? ' - HH:mm:ss' : ''}`
        );
    }
  };

  return renderDate(type);
};

export default DateRender;
