import { ReactElement } from 'react';
import { ClassNames } from './TimeField';

interface ITheme {
  background: string;
  todayBtn: string;
  clearBtn: string;
  icons: string;
  text: string;
  disabledText: string;
  input: string;
  inputIcon: string;
  selected: string;
}

interface IIcons {
  prev: () => ReactElement | JSX.Element;
  next: () => ReactElement | JSX.Element;
}

export interface IOptions {
  title?: string;
  autoHide?: boolean;
  todayBtn?: boolean;
  todayBtnText?: string;
  clearBtn?: boolean;
  clearBtnText?: string;
  maxDate?: Date;
  minDate?: Date;
  theme?: ITheme;
  icons?: IIcons;
  datepickerClassNames?: string;
  defaultDate?: Date | null;
  language?: string;
  weekDays?: string[];
  disabledDates?: Date[];
  inputNameProp?: string;
  inputIdProp?: string;
  inputPlaceholderProp?: string;
  inputDateFormatProp?: Intl.DateTimeFormatOptions;
  showTime?: boolean;
  timeChangeCallback: (newHour: number, newMinute: number) => void;
  twelveHoursClock?: boolean;
  timeClassNames?: ClassNames;
}

const options: IOptions = {
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  todayBtnText: 'Today',
  clearBtnText: 'Clear',
  showTime: false,
  theme: {
    background: '',
    todayBtn: '',
    clearBtn: '',
    icons: '',
    text: '',
    disabledText: '',
    input: '',
    inputIcon: '',
    selected: ''
  },
  datepickerClassNames: '',
  defaultDate: new Date(),
  language: 'en',
  weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  inputNameProp: 'date',
  inputIdProp: 'date',
  inputPlaceholderProp: 'Select Date',
  inputDateFormatProp: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  timeChangeCallback: function (newHour: number, newMinute: number) {
    console.log('Time changed to: ', newHour, newMinute);
  },
  twelveHoursClock: false,
};

export default options;
