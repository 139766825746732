/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

function Upload(props: any) {
  const { onFileChange, width, height, src } = props;
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const onBtnClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          marginBottom: '3vh',
          position: 'relative',
          borderColor: '#d9d9d9',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <img
          aria-hidden="true"
          onClick={onBtnClick}
          src={src || '/upload-default.png'}
          width={width || 'auto'}
          height={height || 'auto'}
          alt=""
        />
      </div>
      <input ref={inputFileRef} onChange={onFileChange} type="file" hidden />
    </div>
  );
}

export default Upload;
