/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import type { ReactNode, CSSProperties } from 'react';
import * as S from './style';
import React from 'react';

export type TagVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'small'
  | 'strong'
  | 'em'
  | 'li';

interface TypographyProps {
  tag: TagVariants
  colors?: colors | React.CSSProperties['color']
  children: ReactNode
  className?: string
  ref?: React.Ref<HTMLElement>
}

// typescript types
export type colors =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'neutral'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export type color = 'black' | 'white' | colors;

const Typography = ({
  tag = 'p',
  children,
  className,
  ref,
  ...props
}: TypographyProps & CSSProperties): React.JSX.Element => (
  <S.DynamicTypography tag={tag} className={className} style={{ ...props }} {...props}>
    {children}
  </S.DynamicTypography>
);

export default Typography;
