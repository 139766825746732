/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import * as React from 'react';
import type { ConfigConsumerProps } from './context';
import { ConfigContext } from './context';
import Skeleton from '../Skeleton';
import StatisticNumber from './Number';
import {StyleStatistic} from './style';
import type { FormatConfig, valueType } from './utils';
import Badge from '@components/elements/Badge';

export interface StatisticProps extends FormatConfig {
  className?: string;
  rootClassName?: string;
  style?: React.CSSProperties;
  value?: valueType;
  valueStyle?: React.CSSProperties;
  valueRender?: (node:any ) => React.ReactNode;
  title?: React.ReactNode;
  prefix?: React.ReactNode | string;
  suffix?: React.ReactNode | string;
  loading?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  badgeValue?: React.ReactNode;
  badgeColor: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info' | 'light' | 'dark' | undefined;
}

const Statistic: React.FC<StatisticProps> = (props) => {
  const {
    className,
    rootClassName,
    style,
    valueStyle,
    value = 0,
    title,
    valueRender,
    prefix,
    suffix,
    loading = false,
    onMouseEnter,
    onMouseLeave,
    decimalSeparator = '.',
    groupSeparator = ',',
    badgeValue,
    badgeColor,
  } = props;

  const { direction, statistic } =
    React.useContext<ConfigConsumerProps>(ConfigContext);

  const valueNode: React.ReactNode = (
    <StatisticNumber
      decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator}
      {...props}
      value={value}
    />
  );

  const cls = classNames(
    'statistic',
    {
      [`statistic-rtl`]: direction === 'rtl'
    },
    statistic?.className,
    className,
    rootClassName,
  );

  return (
    <StyleStatistic
      className={cls}
      style={{ ...statistic?.style, ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {title && <div className={`statistic-title`}>{title}</div>}
      <Skeleton
        paragraph={false}
        loading={loading}
        className={`statistic-skeleton`}
      >
        <div style={valueStyle} className={`statistic-content`}>
          {prefix && (
            <span className={`statistic-content-prefix`}>{prefix}</span>
          )}
          {valueRender ? valueRender(valueNode) : valueNode}
          {suffix && (
            <span className={`statistic-content-suffix`}>{suffix}</span>
          )}
          {badgeValue && (
            <div className={`inline-block statistic-badge ml-4`}>
              <Badge
                shape="square"
                content={badgeValue}
                color={badgeColor}
              />
            </div>
          )}
        </div>
      </Skeleton>
    </StyleStatistic>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Statistic.displayName = 'Statistic';
}

export default Statistic;
