import * as React from 'react';
import { StylePopup } from './style';

export interface ContentProps {
  children: (() => React.ReactNode) | React.ReactNode;
  id?: string;
  overlayInnerStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
}

export default function Popup(props: Readonly<ContentProps>) {
  const { children, id, overlayInnerStyle, className = '', style } =
    props;
  const classNameWrap = `tooltip-inner ` + className;
  return (
    <StylePopup
      className="tooltip-content"
      style={{...style}}
    >
      <div
        className={classNameWrap}
        id={id}
        role="tooltip"
        style={overlayInnerStyle}
      >
        {typeof children === 'function' ? children() : children}
      </div>
    </StylePopup>
  );
}
