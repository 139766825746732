/* eslint-disable no-unused-vars */
import type {
  ActionType,
  AlignType,
  AnimationType,
  ArrowType,
  TriggerProps,
  TriggerRef,
  ArrowPos
} from './interface';
import * as React from 'react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Popup from './Popup';

const arrowPosi = {
  x: 0,
  y: 0
};
export interface TooltipProps
  extends Pick<TriggerProps, 'onPopupAlign' | 'builtinPlacements'> {
  trigger?: ActionType | ActionType[];
  defaultVisible?: boolean;
  visible?: boolean;
  placement?: string;
  /** @deprecated Use `motion` instead */
  transitionName?: string;
  /** @deprecated Use `motion` instead */
  animation?: AnimationType;
  /** Config popup motion */
  onVisibleChange?: (visible: boolean) => void;
  afterVisibleChange?: (visible: boolean) => void;
  content: (() => React.ReactNode) | React.ReactNode;
  overlayStyle?: React.CSSProperties;
  overlayClassName?: string;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  getTooltipContainer?: (node: HTMLElement) => HTMLElement;
  destroyTooltipOnHide?: boolean;
  align?: AlignType;
  showArrow?: boolean | ArrowType;
  arrowContent?: React.ReactNode;
  arrowPos?: ArrowPos;
  id?: string;
  children?: React.ReactElement;
  overlayInnerStyle?: React.CSSProperties;
  zIndex?: number;
  delay?: number;
  stretch?: string;
  targetWidth?: number;
  targetHeight?: number;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface TooltipRef {
  forceAlign: VoidFunction;
}

const Tooltip = (props: TooltipProps, ref: React.Ref<TooltipRef>) => {
  const {
    overlayClassName,
    trigger = props.onClick !== undefined ? ['click'] : ['hover'],
    mouseEnterDelay = 0,
    overlayStyle,
    children,
    overlayInnerStyle,
    content,
    id,
    arrowPos = arrowPosi,
    stretch,
    targetWidth,
    targetHeight,
    zIndex,
    style,
    onClick,
    ...restProps
  } = props;

  const triggerRef = useRef<TriggerRef>(null);
  // @ts-ignore
  useImperativeHandle(ref, () => triggerRef.current);

  const extraProps: Partial<TooltipProps & TriggerProps> = { ...restProps };
  if ('visible' in props) {
    extraProps.popupVisible = props.visible;
  }

  let timeout: string | number | NodeJS.Timeout | undefined;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, mouseEnterDelay * 1000 || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  // >>>>> Misc
  const miscStyle: React.CSSProperties = {};
  if (stretch) {
    if (stretch.includes('height') && targetHeight) {
      miscStyle.height = targetHeight;
    } else if (stretch.includes('minHeight') && targetHeight) {
      miscStyle.minHeight = targetHeight;
    }
    if (stretch.includes('width') && targetWidth) {
      miscStyle.width = targetWidth;
    } else if (stretch.includes('minWidth') && targetWidth) {
      miscStyle.minWidth = targetWidth;
    }
  }

  const getPopupElement = () => (
    <Popup
      key="content"
      id={id}
      overlayInnerStyle={overlayInnerStyle}
      className={overlayClassName}
      style={
        {
          '--arrow-x': `${arrowPos.x ?? 0}px`,
          '--arrow-y': `${arrowPos.y ?? 0}px`,
          ...miscStyle,
          boxSizing: 'border-box',
          zIndex,
          ...style,
        } as React.CSSProperties
      }
    >
      {content}
    </Popup>
  );

  return (
    <div
      action={trigger}
      // @ts-ignore
      ref={triggerRef}
      aria-hidden="true"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      style={overlayStyle}
      onClick={onClick}
      {...extraProps}
    >
      {children}
      {active === true && getPopupElement()}
    </div>
  );
};

export default forwardRef(Tooltip);
