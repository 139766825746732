import styled from 'styled-components';

export const StyleBackTop = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  z-index: 99;
  display: block;
  border: none;
  position: fixed;
  width: 40px;
  height: auto;
  box-shadow: none;
  min-height: 40px;
  inset-inline-end: 24px;
  inset-block-end: 48px;
  border-radius: 8px;
  inset-inline-end: 24px;
  inset-block-end: 48px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transition: background-color 0.2s;
  border-radius: 50%;
  .top-to-btm {
    position: static;
    background-color: #ffffff;
    transition: background-color 0.2s;
    height: 40px;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
    .icon-bage {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      line-height: 1;
      list-style: none;
      font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
      position: relative;
      display: inline-block;
    }
    .icon-bage-content {
      overflow: hidden;
      text-align: center;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 4px;
    }
  }
  .icon-position {
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }
`;