import styled from 'styled-components';

export const StyleButton = styled.button`
  font-weight: 400;
  &.btn-blue-sky {
    background-color: #1990FF;
    border-color: #1990FF;
    color: #FFFFFF;
    &:hover {
      background-color: #47A6FF;
      border-color: #47A6FF;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #0071DC;
      border-color: #0071DC;
    }
    &.loading {
      background-color: #1990FF;
      border-color: #1990FF;
    }
  }
  &.btn-success {
    background-color: #33FF60;
    border-color: #33FF60;
    color: #FFFFFF;
    &:hover {
      background-color: #6BFF8B;
      border-color: #6BFF8B;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #0DE83D;
      border-color: #0DE83D;
    }
    &.loading {
      background-color: #33FF60;
      border-color: #33FF60;
    }
  }
  &.btn-warning {
    background-color: #FF9E00;
    border-color: #FF9E00;
    color: #FFFFFF;
    &:hover {
      background-color: #FFB948;
      border-color: #FFB948;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #DC8800;
      border-color: #DC8800;
    }
    &.loading {
      background-color: #FF9E00;
      border-color: #FF9E00;
    }
  }
  &.btn-secondary {
    background-color: #F4F6F9;
    border-color: #F4F6F9;
    color: #1990FF;
    &:hover {
      background-color: #DFEFFF;
      border-color: #DFEFFF;
      color: #1990FF;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #B7DCFF;
      border-color: #B7DCFF;
    }
    &.loading {
      background-color: #F4F6F9;
      border-color: #F4F6F9;
    }
  }
  &.btn-error {
    background-color: #FF2D49;
    border-color: #FF2D49;
    color: #FFFFFF;
    &:hover {
      background-color: #FF7184;
      border-color: #FF7184;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #E9001F;
      border-color: #E9001F;
    }
    &.loading {
      background-color: #FF2D49;
      border-color: #FF2D49;
    }
  }
  &.btn-info {
    background-color: #3abff8;
    border-color: #3abff8;
    color: #FFFFFF;
    &:hover {
      background-color: #60c8f4;
      border-color: #60c8f4;
    }
    &.active,
    &.focus,
    &:active,
    &:focus {
      background-color: #12b1f4;
      border-color: #12b1f4;
    }
    &.loading {
      background-color: #3abff8;
      border-color: #3abff8;
    }
  }
  &:disabled,
  &.disabled {
    background-color: #F4F6F9;
    border-color: #F4F6F9;
    color: #ADABB9;
  }
  &.btn-outline {
    background-color: transparent;
    &:disabled,
    &.disabled {
      background-color: transparent;
    }
    &:hover {
      color: #FFFFFF;
    }
    &.btn-blue-sky {
      color: #1990FF;
      &:hover {
        background-color: #47A6FF;
        border-color: #47A6FF;
        color: #FFFFFF;
      }
    }
    &.btn-warning {
      color: #FF9E00;
      &:hover {
        background-color: #FFB948;
        border-color: #FFB948;
        color: #FFFFFF;
      }
    }
    &.btn-success {
      color: #33FF60;
      &:hover {
        background-color: #6BFF8B;
        border-color: #6BFF8B;
        color: #FFFFFF;
      }
    }
    &.btn-error {
      color: #FF2D49;
      &:hover {
        background-color: #FF7184;
        border-color: #FF7184;
        color: #FFFFFF;
      }
    }
    &.btn-info {
      color: #3abff8;
      &:hover {
        background-color: #60c8f4;
        border-color: #60c8f4;
        color: #FFFFFF;
      }
    }
    &:disabled,
    &.disabled {
      background-color: transparent;
      border-color: #ADABB9;
      color: #ADABB9;
    }
  }
  &.btn-link {
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    text-decoration: none;
    &:hover {
      color: #47A6FF;
      background-color: transparent;
      border-color: transparent;
    }
    &.btn-blue-sky {
      color: #1990FF;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #DFEFFF;;
        background-color: transparent;
        border-color: transparent;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #005ECC;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #009EF7;
      }
    }
    &.btn-success {
      color: #33FF60;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #33FF60;
        background-color: #E9FFEE;
        border-color: #E9FFEE;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #0DE83D;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #33FF60;
      }
    }
    &.btn-warning {
      color: #FF9E00;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #FF9E00;
        background-color: #FFEFD4;
        border-color: #FFEFD4;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #DC8800;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #FF9E00;
      }
    }
    &.btn-secondary {
      color: #8E8D99;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #8E8D99;
        background-color: #ECEBF6;
        border-color: #ECEBF6;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #7C7A8A;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #8E8D99;
      }
    }
    &.btn-info {
      color: #3abff8;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #3abff8;
        background-color: #b5e4f8;
        border-color: #b5e4f8;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #7C7A8A;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #3abff8;
      }
    }
    &.btn-error {
      color: #FF2D49;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: #FF2D49;
        background-color: #FFD5DB;
        border-color: #FFD5DB;
      }
      &.active,
      &.focus,
      &:active,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: #E9001F;
      }
      &.loading {
        border-color: transparent;
        background-color: transparent;
        color: #FF2D49;
      }
    }
    &:disabled,
    &.disabled {
      background-color: transparent;
      color: #ADABB9;
    }
  }
  &.icon {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &.btn-lg {
    height: 3.5rem;
    padding-left: 46px;
    padding-right: 46px;
    min-height: 3.5rem;
    font-size: 1.125rem;
  }
  &.btn-sm {
    height: 2.5rem;
    padding-left: 22px;
    padding-right: 22px;
    min-height: 2.5rem;
    font-size: 0.875rem;
  }
  &.btn-xs {
    height: 2rem;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 2rem;
    font-size: 0.575rem;
    &.btn-rounded {
      border-radius: 8px !important;
    }
  }
  &.btn-rounded {
    border-radius: 14px !important;
  }
  &.btn-square {
    border-radius: 0 !important;
  }
  &.btn-loading {
    opacity: .65;
    cursor: default;
  }
  &.btn-block {
    width: 100%;
  }
`;